import { Theme } from "model/theme";
import {
  createStyle,
  flexRowMixin,
  flexItemMixin,
  TextVariant,
  ThemeStyleColorVariant,
  StyleVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface ColorTagListStyleProps {
  wrapContent?: boolean;
  emptyListTextVariant?: TextVariant;
  emptyListTextColorVariant?: ThemeStyleColorVariant;
}

interface ClassNames {
  root: string;
  tag: string;
  emptyListText: string;
}

const useColorTagListStaticStyles = createStyle(
  (_theme: Theme) => ({
    tag: {
      ...flexItemMixin({ shrink: 0 })
    }
  }),
  "ColorTagListStatic"
);

const useColorTagListDynamicStyles = createStyle(
  (theme: Theme) => ({
    root: (props: ColorTagListStyleProps) => ({
      ...flexRowMixin({ gap: 8, wrapContent: props.wrapContent }),
      maxWidth: "100%"
    }),
    emptyListText: (props: ColorTagListStyleProps) => ({
      ...themeTextMixin(
        theme,
        props.emptyListTextVariant ?? TextVariant.Caption,
        1
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.emptyListTextColorVariant ?? ThemeStyleColorVariant.GrayDark
      )
    })
  }),
  "TagWithPeriodListDynamic"
);

export const useColorTagListStyles = (
  props: ColorTagListStyleProps
): ClassNames => {
  const { tag } = useColorTagListStaticStyles();
  const { root, emptyListText } = useColorTagListDynamicStyles(props);

  return {
    root,
    tag,
    emptyListText
  };
};
