import {
  WebsocketClientContext,
  WebsocketClientContextValue
} from "providers/WebsocketClient/types";
import { createContext, useContext } from "react";
import { Optional, WebSocketClient } from "@laba/ts-common";

interface InitWebsocketClientProviderReturn {
  context: WebsocketClientContext;
  useGenericWebsocketClientGetter: () => WebSocketClient;
  useIsGenericWebsocketClientConnected: () => boolean;
}

export const useGetGenericContextWebsocketClientValue = (
  context: WebsocketClientContext
): Optional<WebsocketClientContextValue> => {
  return useContext<Optional<WebsocketClientContextValue>>(context);
};

const useGetContextValue = (context: WebsocketClientContext) => {
  const contextValue =
    useContext<Optional<WebsocketClientContextValue>>(context);
  if (contextValue === undefined) {
    // we know contextValue isn't undefined at runtime if this return hook is called inside a provider
    throw new Error(
      "dont call this hook without a wrapped WebsocketClientProvider "
    );
  }
  return contextValue;
};

export const initGenericWebsocketClientProvider =
  (): InitWebsocketClientProviderReturn => {
    const context =
      createContext<Optional<WebsocketClientContextValue>>(undefined);

    const useGenericWebsocketClientGetter = (): WebSocketClient => {
      return useGetContextValue(context).client;
    };

    const useIsGenericWebsocketClientConnected = (): boolean => {
      return useGetContextValue(context).isConnected;
    };

    return {
      context,
      useGenericWebsocketClientGetter,
      useIsGenericWebsocketClientConnected
    };
  };
