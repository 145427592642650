import React, { useState } from "react";
import {
  FC,
  getClassName,
  stopPropagationWrapper,
  StyleVariant
} from "@laba/react-common";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import {
  PatientAllergies,
  PatientAllergyStatusFlatData
} from "components/headers/PatientHeader/PatientAllergies/PatientAllergies";
import { PatientTags } from "components/headers/PatientHeader/PatientTags/PatientTags";
import { usePatientHeaderStylesClassNames } from "components/headers/PatientHeader/usePatientHeaderStyles";
import { PatientIdentificationDataContainer } from "components/headers/PatientHeader/PatientIdentificationDataContainer/PatientIdentificationDataContainer";
import { isEmpty } from "lodash-es";
import {
  PatientDetailDataItem,
  PatientDetailDataItemProps
} from "components/headers/PatientHeader/PatientDetailDataItem/PatientDetailDataItem";
import { FloatingButtons } from "components/headers/PatientHeader/FloatingButtons/FloatingButtons";
import { Divider, DividerOrientation } from "components/divider/Divider";
import { EditIcon } from "components/icons";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { PatientDetailMobilePopup } from "components/headers";
import { TagFlatData } from "components/tags";
import { Noop } from "@laba/ts-common";

export enum PatientDetailDataSize {
  Large = "Large",
  Small = "Small"
}

export interface PatientDetailData extends PatientDetailDataItemProps {
  size?: PatientDetailDataSize;
}

export interface PatientHeaderProps {
  isMobile?: boolean;
  className?: string;
  isExpansible?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  profilePictureUrl?: string;
  name: string;
  lastName: string;
  patientIdNumber?: string;
  patientIdTitle?: string;
  primaryData?: PatientDetailData[];
  secondaryData?: PatientDetailData[];
  showAllergies?: boolean;
  allergiesTitle?: string;
  noAllergiesText?: string;
  untestedForAllergiesText?: string;
  allergyStatus?: PatientAllergyStatusFlatData;
  allergies?: string[];
  showTags?: boolean;
  tags?: TagFlatData[];
  onEditButtonClick?: Noop;
  handleClick?: Noop;
  ageText?: string;
  birthDateText?: string;
}

const filterNotUndefinedData = (data?: PatientDetailData[]) =>
  data?.filter(item => !Object.values(item).every(el => el === undefined));

export const PatientHeader: FC<PatientHeaderProps> = ({
  isMobile,
  className,
  isExpansible,
  isExpanded,
  isEditable,
  profilePictureUrl,
  name,
  lastName,
  primaryData,
  secondaryData,
  showAllergies = true,
  showTags = true,
  allergiesTitle,
  noAllergiesText,
  untestedForAllergiesText,
  allergyStatus,
  allergies,
  tags,
  onEditButtonClick,
  handleClick,
  patientIdNumber,
  patientIdTitle,
  ageText,
  birthDateText
}) => {
  const classes = usePatientHeaderStylesClassNames({
    showTags,
    isExpanded: isExpanded ?? false,
    isExpansible,
    isMobile
  });
  const patientID =
    patientIdNumber && patientIdTitle
      ? [patientIdTitle, patientIdNumber].join(" ")
      : undefined;

  const filteredPrimaryData = filterNotUndefinedData(primaryData);
  const filteredSecondaryData = filterNotUndefinedData(secondaryData);

  const [isPatientDetailPopupOpen, setIsPatientDetailPopupOpen] =
    useState(false);

  const onClosePatientDetailMobilePopup = () => {
    setIsPatientDetailPopupOpen(false);
  };

  const onOpenPatientDetailMobilePopup = () => {
    setIsPatientDetailPopupOpen(true);
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        className={getClassName(className, classes.header)}
        onClick={() => {
          isMobile ? onOpenPatientDetailMobilePopup() : handleClick?.();
        }}
        disableRipple
        selectable
      >
        {isMobile ? (
          <PatientIdentificationDataContainer
            className={classes.mobileIdentificationDataContainer}
            isMobile={isMobile}
            firstName={name}
            lastName={lastName}
            profilePictureUrl={profilePictureUrl}
            patientID={patientID}
            ageText={ageText}
            birthDateText={birthDateText}
          />
        ) : (
          <div className={classes.identificationDataContainer}>
            <PatientIdentificationDataContainer
              className={classes.identificationData}
              firstName={name}
              lastName={lastName}
              profilePictureUrl={profilePictureUrl}
              patientID={patientID}
            />
            <Divider
              orientation={DividerOrientation.Vertical}
              className={classes.verticalDivider}
            />
          </div>
        )}
        {!isMobile &&
          !isEmpty(primaryData) &&
          filteredPrimaryData?.map((dataItem, index) => (
            <PatientDetailDataItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={
                dataItem.size === PatientDetailDataSize.Large
                  ? classes.primaryDataItemBig
                  : classes.primaryDataItemSmall
              }
              textList={dataItem.textList}
              showSeparator={dataItem.showSeparator}
              textVariant={dataItem.textVariant}
            />
          ))}
        {showAllergies && !isMobile && (
          <div className={classes.allergyItem}>
            {!isEmpty(primaryData) && (
              <Divider
                orientation={DividerOrientation.Vertical}
                className={classes.verticalDivider}
              />
            )}
            <PatientAllergies
              allergiesTitle={allergiesTitle}
              noAllergiesText={noAllergiesText}
              untestedForAllergiesText={untestedForAllergiesText}
              allergyStatus={allergyStatus}
              allergies={allergies}
              maxLines={2}
            />
          </div>
        )}
        {isMobile && (
          <div className={classes.mobileActionsButtons}>
            {showAllergies && (
              <PatientAllergies
                showIconOnly={isMobile}
                allergiesTitle={allergiesTitle}
                noAllergiesText={noAllergiesText}
                untestedForAllergiesText={untestedForAllergiesText}
                allergyStatus={allergyStatus}
                allergies={allergies}
              />
            )}
            {isEditable && (
              <NexupIconButton
                Icon={EditIcon}
                style={StyleVariant.BlackWhite}
                type={TypeInvertedVariant.Inverted}
                onClick={stopPropagationWrapper(onEditButtonClick)}
              />
            )}
          </div>
        )}
        {!isMobile && isExpanded && (
          <Divider className={classes.horizontalDivider} />
        )}
        {!isMobile &&
          isExpanded &&
          filteredSecondaryData?.map((dataItem, index) => (
            <PatientDetailDataItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={
                dataItem.size === PatientDetailDataSize.Large
                  ? classes.secondaryDataItemBig
                  : classes.secondaryDataItemSmall
              }
              textList={dataItem.textList}
              showSeparator={dataItem.showSeparator}
              textVariant={dataItem.textVariant}
            />
          ))}
        {!isMobile && showTags && (
          <div className={classes.tagsItemContainer}>
            <Divider
              orientation={DividerOrientation.Vertical}
              className={classes.verticalDivider}
            />
            <div className={classes.tagsHeaderContainer}>
              <PatientTags tags={tags} isExpanded={isExpanded} />
            </div>
          </div>
        )}
      </ButtonBase>
      {!isMobile && (
        <FloatingButtons
          className={classes.floatingButtons}
          isExpanded={isExpanded}
          isExpansible={isExpansible}
          isEditable={isEditable}
          onExpandCollapseButtonClick={handleClick}
          onEditButtonClick={onEditButtonClick}
        />
      )}
      <PatientDetailMobilePopup
        isOpen={isPatientDetailPopupOpen}
        onClose={onClosePatientDetailMobilePopup}
        isEditable={isEditable}
        firstName={name}
        lastName={lastName}
        patientDetailData={filterNotUndefinedData([
          patientID ? { textList: [{ text: patientID }] } : {},
          ...(filteredPrimaryData ?? []),
          ...(filteredSecondaryData ?? [])
        ])}
        tags={tags}
        showAllergies={showAllergies}
        allergiesTitle={allergiesTitle}
        noAllergiesText={noAllergiesText}
        untestedForAllergiesText={untestedForAllergiesText}
        allergyStatus={allergyStatus}
        allergies={allergies}
        onEditClick={() => {
          if (isPatientDetailPopupOpen) onClosePatientDetailMobilePopup();
          onEditButtonClick?.();
        }}
      />
    </div>
  );
};
