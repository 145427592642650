import { WhatsappNotificationStatus } from "model/communication";

export interface WhatsappMessage {
  senderId?: string;
  from?: string;
  to?: string;
  id?: string;
  body?: string;
  status?: WhatsappNotificationStatus;
  linkUrl?: string;
}

export enum WhatsappNumberValidationCode {
  Valid = "Valid",
  Invalid = "Invalid",
  ConnectionError = "ConnectionError"
}
