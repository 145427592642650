import { ReactElementOrNull } from "@laba/react-common";
import React from "react";
import {
  AutocompleteOption,
  AutocompleteOptionProps
} from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";
import { IconWithText } from "components/IconWithText/IconWithText";

export type AutocompleteOptionWithEndIconWithTextProps<T> =
  AutocompleteOptionProps<T>;

export const AutocompleteOptionWithEndIconWithText = <T,>({
  option,
  ...rest
}: AutocompleteOptionWithEndIconWithTextProps<T>): ReactElementOrNull => {
  const { iconText, iconTextColor } = option;
  return (
    <AutocompleteOption {...rest} option={option}>
      {iconText && iconTextColor && (
        <IconWithText text={iconText} color={iconTextColor} notShrinkText />
      )}
    </AutocompleteOption>
  );
};
