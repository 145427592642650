import React from "react";
import { FC, StyleVariant, getClassName } from "@laba/react-common";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { FilterListIcon } from "components/icons";
import {
  BadgePosition,
  BadgeVariant,
  BadgedComponent
} from "components/containers/BadgedComponent/BadgedComponent";
import { Noop, isGreaterThan0 } from "@laba/ts-common";
import { useFiltersButtonStyle } from "./useFiltersButtonStyle";

export interface FiltersButtonProps {
  isMobile?: boolean;
  appliedFiltersCount?: number;
  className?: string;
  filtersText: string;
  onClick?: Noop;
  isExpanded: boolean;
  title?: string;
}

const badgeVariant = BadgeVariant.Standard;
const badgePosition = BadgePosition.BottomRight;

export const FiltersButton: FC<FiltersButtonProps> = ({
  isMobile,
  appliedFiltersCount,
  className,
  filtersText,
  onClick,
  isExpanded,
  title
}) => {
  const classes = useFiltersButtonStyle();

  const isBadgeVisible = !isExpanded && isGreaterThan0(appliedFiltersCount);

  if (isMobile) {
    return (
      <NexupIconButton
        className={isExpanded ? classes.expandedIconButton : undefined}
        style={StyleVariant.BlackWhite}
        type={TypeInvertedVariant.Inverted}
        title={title}
        onClick={onClick}
        Icon={FilterListIcon}
        withBadge
        isBadgeVisible={isBadgeVisible}
        badgeContent={appliedFiltersCount}
        badgeVariant={badgeVariant}
        badgePosition={badgePosition}
      />
    );
  }

  return (
    <ButtonBase
      className={getClassName(classes.container, className)}
      onClick={onClick}
      title={title}
    >
      <BadgedComponent
        isVisible={isBadgeVisible}
        badgeContent={appliedFiltersCount}
        variant={badgeVariant}
        position={badgePosition}
      >
        <FilterListIcon className={classes.icon} />
      </BadgedComponent>
      <span className={classes.filtersText}>{filtersText}</span>
    </ButtonBase>
  );
};
