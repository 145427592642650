import { FC, TextVariant, getClassName } from "@laba/react-common";
import React, { Fragment } from "react";
import { usePatientDetailDataItemStyles } from "components/headers/PatientHeader/PatientDetailDataItem/usePatientDetailDataItemStyles";
import { joinText } from "@laba/ts-common";
import { isEmpty, isEqual } from "lodash-es";
import { UrlLink } from "components/link";

export interface PatientDetailDataSingleItem {
  text?: string;
  url?: string;
}

export interface PatientDetailDataItemProps {
  className?: string;
  textVariant?: TextVariant;
  textList?: PatientDetailDataSingleItem[];
  showSeparator?: boolean;
  isMobile?: boolean;
}

export const PatientDetailDataItem: FC<PatientDetailDataItemProps> = ({
  className,
  textVariant = TextVariant.Caption,
  textList,
  showSeparator,
  isMobile
}) => {
  const classes = usePatientDetailDataItemStyles({ textVariant, isMobile });

  if (isEmpty(textList)) return null;

  const joinedText = joinText(
    textList?.map(textItem => textItem.text),
    " "
  );

  return (
    <div className={getClassName(className, classes.root)} title={joinedText}>
      {textList?.map((item, idx) => {
        const { url, text } = item;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={idx}>
            {!isEqual(idx, 0) && showSeparator && (
              <p className={classes.text}> | </p>
            )}
            {url ? (
              <UrlLink
                url={url}
                displayText={text}
                textVariant={textVariant}
                textUnderline
              />
            ) : !isEmpty(text) ? (
              <p className={classes.text}>{text}</p>
            ) : isMobile ? undefined : (
              <p className={classes.text}>-</p>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
