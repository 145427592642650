import { ThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import {
  Color,
  FCC,
  getClassName,
  IconC,
  ReactElement,
  ReactElementOrNull,
  useConfirmExitDialog
} from "@laba/react-common";
import React, { useEffect, useRef } from "react";
import { Noop } from "@laba/ts-common";
import { SizeVariant, StyleVariant, TypeVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { DialogContent } from "@material-ui/core";
import { Spinner } from "components/spinners/Spinner/Spinner";
import {
  PopupSizeVariant,
  PopupVariant,
  useBasePopupStyle,
  useDialogContentStyles,
  useMuiBasePopupStyleClassNames
} from "components/popup/BasePopup/BasePopupStyle";
import {
  ButtonType,
  DefaultButton
} from "components/buttons/DefaultButton/DefaultButton";
import { ScrollableContainer } from "components/containers/ScrollableContainer/ScrollableContainer";
import { isEmpty, isUndefined } from "lodash-es";
import { NavigationBlocker } from "components/utils/NavigatorBlocker";
import { BasePopupHeaderTitle } from "./BasePopupHeaderTitle/BasePopupHeaderTitle";

export interface BasePopupProps {
  className?: string;
  paperClassName?: string;
  childrenClassName?: string;
  disableBackdropClick?: boolean;
  closableHeader?: boolean;
  disableClose?: boolean;
  headerColor?: Color;
  headerTitle?: string;
  headerSubtitle?: string;
  headerWithColor?: boolean;
  loading?: boolean;
  onClose?: Noop;
  open?: boolean;
  style?: StyleVariant;
  primaryButtonStyle?: StyleVariant;
  popupVariant?: PopupVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  deleteButtonText?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  onDeleteButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  DeleteButtonIcon?: IconC;
  formId?: string;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  disableDeleteButton?: boolean;
  ExtraRightIconHeader?: IconC;
  onExtraRightIconClick?: Noop;
  ExtraLeftIconHeader?: IconC;
  extraLeftIconHeaderStyle?: StyleVariant;
  disabledExtraRightIconHeader?: boolean;
  disabledExtraLeftIconHeader?: boolean;
  popupSizeVariant?: PopupSizeVariant;
  PopupHeader?: ReactElement;
  showConfirmDialogOnClose?: boolean;
  confirmDialogMessage?: string;
  dirtyContent?: boolean;
  fullWidth?: boolean;
  dropMargins?: boolean;
  isCommercialPopup?: boolean;
  CustomSecondaryButton?: ReactElement;
  dropTopMargin?: boolean;
}

export const BasePopup: FCC<BasePopupProps> = ({
  onClose,
  children,
  headerColor,
  headerTitle,
  headerSubtitle,
  headerWithColor,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onDeleteButtonClick,
  primaryButtonText,
  secondaryButtonText,
  deleteButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  DeleteButtonIcon,
  className,
  paperClassName,
  childrenClassName,
  disabledExtraRightIconHeader,
  disabledExtraLeftIconHeader,
  CustomSecondaryButton,
  disableBackdropClick = false,
  style = StyleVariant.Primary,
  primaryButtonStyle,
  open = false,
  closableHeader = false,
  disableClose = false,
  popupVariant = PopupVariant.Horizontal,
  formId,
  loading = false,
  disablePrimaryButton = false,
  disableSecondaryButton = false,
  disableDeleteButton = false,
  ExtraRightIconHeader,
  onExtraRightIconClick,
  ExtraLeftIconHeader,
  extraLeftIconHeaderStyle = StyleVariant.BlackWhite,
  popupSizeVariant = SizeVariant.Large,
  PopupHeader,
  showConfirmDialogOnClose = false,
  confirmDialogMessage = "",
  dirtyContent = false,
  fullWidth = false,
  dropMargins = false,
  isCommercialPopup = false,
  dropTopMargin = false
}) => {
  const showPrimaryButton =
    Boolean(primaryButtonText) &&
    (Boolean(onPrimaryButtonClick) || Boolean(formId));
  const showSecondaryButton =
    Boolean(secondaryButtonText) &&
    Boolean(onSecondaryButtonClick) &&
    !CustomSecondaryButton;
  const showDeleteButton =
    Boolean(deleteButtonText) && Boolean(onDeleteButtonClick);
  const showButtons =
    showPrimaryButton || showSecondaryButton || showDeleteButton;
  const theme = useMuiTheme(style);
  const muiClasses = useMuiBasePopupStyleClassNames({
    size: popupSizeVariant,
    variant: popupVariant,
    fullWidth
  });
  const dialogContentRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (loading && dialogContentRef.current != null) {
      dialogContentRef.current.scrollTo(0, 0);
    }
  }, [dialogContentRef, loading]);
  const classes = useBasePopupStyle({
    fullWidth,
    size: popupSizeVariant,
    variant: popupVariant,
    dropMargins,
    loading,
    showButtons,
    withCommercialBackground: isCommercialPopup,
    hasChildren: isEmpty(children),
    dropTopMargin
  });
  const dialogContentClasses = useDialogContentStyles();

  const wrappedOnClose = useConfirmExitDialog(
    showConfirmDialogOnClose,
    dirtyContent,
    confirmDialogMessage,
    onClose
  );

  const wrappedSecondaryButtonClicked = useConfirmExitDialog(
    showConfirmDialogOnClose,
    dirtyContent,
    confirmDialogMessage,
    onSecondaryButtonClick
  );

  const FooterButtons: ReactElementOrNull = showButtons ? (
    <div className={classes.footerButtons}>
      {showPrimaryButton && (
        <DefaultButton
          onClick={onPrimaryButtonClick}
          text={primaryButtonText}
          title={primaryButtonText}
          size={SizeVariant.Small}
          style={primaryButtonStyle ?? style}
          formId={formId}
          buttonType={formId ? ButtonType.Submit : ButtonType.Button}
          StartIcon={PrimaryButtonIcon}
          disabled={loading || disablePrimaryButton}
        />
      )}
      {!showSecondaryButton && CustomSecondaryButton
        ? CustomSecondaryButton
        : null}
      {showSecondaryButton && (
        <DefaultButton
          className={classes.secondaryButton}
          onClick={wrappedSecondaryButtonClicked}
          text={secondaryButtonText}
          title={secondaryButtonText}
          size={SizeVariant.Small}
          type={
            !isCommercialPopup ? TypeVariant.Outlined : TypeVariant.Inverted
          }
          style={style}
          StartIcon={SecondaryButtonIcon}
          disabled={loading || disableSecondaryButton}
        />
      )}
      {showDeleteButton && (
        <DefaultButton
          className={classes.deleteButton}
          onClick={onDeleteButtonClick}
          text={deleteButtonText}
          title={deleteButtonText}
          size={SizeVariant.Small}
          type={TypeVariant.Outlined}
          style={StyleVariant.Danger}
          StartIcon={DeleteButtonIcon}
          disabled={loading || disableDeleteButton}
        />
      )}
    </div>
  ) : null;

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        className={className}
        classes={muiClasses}
        open={open}
        onClose={(_, reason) => {
          if (!loading) {
            if (reason === "backdropClick") {
              if (!disableBackdropClick && !disableClose) wrappedOnClose();
            } else {
              wrappedOnClose();
            }
          }
        }}
        PaperProps={{ className: getClassName(paperClassName, classes.paper) }}
        onClick={event => event.stopPropagation()}
      >
        {!isUndefined(PopupHeader) ? (
          <div className={classes.popupHeader}>{PopupHeader}</div>
        ) : closableHeader ? (
          <div className={classes.popupHeader}>
            <BasePopupHeaderTitle
              style={style}
              color={headerColor}
              withColor={headerWithColor}
              onCloseClick={wrappedOnClose}
              headerTitle={headerTitle}
              headerSubtitle={headerSubtitle}
              disableClose={disableClose || loading}
              ExtraRightIconHeader={ExtraRightIconHeader}
              onExtraRightIconClick={onExtraRightIconClick}
              ExtraLeftIconHeader={ExtraLeftIconHeader}
              leftIconStyle={extraLeftIconHeaderStyle}
              disabledExtraRightIconHeader={disabledExtraRightIconHeader}
              disabledExtraLeftIconHeader={disabledExtraLeftIconHeader}
            />
          </div>
        ) : null}
        <ScrollableContainer className={classes.scrollableContainer}>
          <DialogContent
            ref={dialogContentRef}
            className={getClassName(classes.dialogContent, childrenClassName)}
            classes={dialogContentClasses}
          >
            {children}
            {loading && (
              <div className={classes.loadingScreen}>
                <Spinner />
              </div>
            )}
          </DialogContent>
        </ScrollableContainer>
        {FooterButtons}
      </Dialog>
      {open && <NavigationBlocker onClose={wrappedOnClose} />}
    </ThemeProvider>
  );
};
