import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const SensorDisabledIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="sensors_off"
        d="M19.775 22.625L8.125 10.975C8.09167 11.1417 8.0625 11.3084 8.0375 11.475C8.0125 11.6417 8 11.8167 8 12C8 12.55 8.10417 13.0667 8.3125 13.55C8.52083 14.0334 8.80833 14.4584 9.175 14.825L7.75 16.25C7.21667 15.7 6.79167 15.0625 6.475 14.3375C6.15833 13.6125 6 12.8334 6 12C6 11.5334 6.05 11.0834 6.15 10.65C6.25 10.2167 6.39167 9.80838 6.575 9.42505L5.1 7.95005C4.75 8.55005 4.47917 9.18755 4.2875 9.86255C4.09583 10.5375 4 11.25 4 12C4 13.1167 4.20833 14.1584 4.625 15.125C5.04167 16.0917 5.61667 16.9334 6.35 17.65L4.925 19.075C4.025 18.1584 3.3125 17.0959 2.7875 15.8875C2.2625 14.6792 2 13.3834 2 12C2 10.9667 2.14583 9.99172 2.4375 9.07505C2.72917 8.15838 3.13333 7.30005 3.65 6.50005L1.375 4.22505L2.8 2.80005L21.2 21.2L19.775 22.625ZM20.35 17.5L18.9 16.05C19.25 15.45 19.5208 14.8125 19.7125 14.1375C19.9042 13.4625 20 12.75 20 12C20 10.8834 19.7917 9.84172 19.375 8.87505C18.9583 7.90838 18.3833 7.06672 17.65 6.35005L19.075 4.92505C19.975 5.82505 20.6875 6.87922 21.2125 8.08755C21.7375 9.29588 22 10.6 22 12C22 13.0167 21.8542 13.9875 21.5625 14.9125C21.2708 15.8375 20.8667 16.7 20.35 17.5ZM17.425 14.575L15.875 13.025C15.9083 12.8584 15.9375 12.6917 15.9625 12.525C15.9875 12.3584 16 12.1834 16 12C16 11.45 15.8958 10.9334 15.6875 10.45C15.4792 9.96672 15.1917 9.54172 14.825 9.17505L16.25 7.75005C16.7833 8.28338 17.2083 8.91255 17.525 9.63755C17.8417 10.3625 18 11.15 18 12C18 12.4667 17.95 12.9167 17.85 13.35C17.75 13.7834 17.6083 14.1917 17.425 14.575Z"
        fill="#FB7878"
      />
    </SvgIcon>
  );
};
