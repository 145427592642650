import {
  FC,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { isEqual } from "lodash-es";
import React from "react";
import { useParagraphWithSpansStyles } from "./useParagraphWithSpansStyles";

export enum TextFormat {
  Normal = "Normal",
  Bold = "Bold"
}

interface TextWithFormat {
  text: string;
  format?: TextFormat;
}

const generateTextWithFormatListFromString = (
  text: string
): TextWithFormat[] => {
  const parts = text.split(/(<strong>.*?<\/strong>)/);

  return parts
    .map(part => {
      const isBold = /<strong>(.*?)<\/strong>/.test(part);
      const partText = part.replace(/<strong>|<\/strong>/g, "");
      return partText
        ? {
            text: partText,
            format: isBold ? TextFormat.Bold : TextFormat.Normal
          }
        : null;
    })
    .filter(part => part !== null) as TextWithFormat[];
};

interface Props {
  text: string;
  textVariant?: TextVariant;
  boldTextVariant?: TextVariant;
  maxLines?: number;
  styleVariant?: StyleVariant;
  colorVariant?: ThemeStyleColorVariant;
}

export const ParagraphWithSpans: FC<Props> = ({
  text,
  textVariant = TextVariant.Body1,
  colorVariant = ThemeStyleColorVariant.GrayDark,
  boldTextVariant = TextVariant.Subtitle1,
  styleVariant = StyleVariant.Primary,
  maxLines
}) => {
  const classes = useParagraphWithSpansStyles({
    colorVariant,
    styleVariant,
    textVariant,
    maxLines,
    boldTextVariant
  });

  const textWithFormatList = generateTextWithFormatListFromString(text);
  return (
    <p className={classes.text}>
      {textWithFormatList.map(textWithFormat =>
        isEqual(textWithFormat.format, TextFormat.Bold) ? (
          <span className={classes.bold}>{textWithFormat.text}</span>
        ) : (
          textWithFormat.text
        )
      )}
    </p>
  );
};
