import { createStyle, FlexAlignItems, flexRowMixin } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeStyleColor,
  themeTextMixin,
  getThemeStyleState
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

interface Props {
  textVariant: TextVariant;
}

export const useSwitchToggleStyle = createStyle(
  (theme: Theme) => ({
    container: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      })
    },
    text: (props: Props) => ({
      ...themeTextMixin(theme, props.textVariant, 1)
    })
  }),
  "SwitchToggle"
);

export const useSwitchToggleStyleOverrides = (
  theme: Theme
): Record<string, unknown> => {
  return {
    MuiSwitchToggle: {
      switchBase: {
        color: getThemeStyleState(theme, StyleVariant.Primary)
          .disabledBackground,
        "&$checked$checked": {
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.Light
          )
        },
        "&$disabled$disabled": {
          color: getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.GrayLight
          )
        }
      },
      track: {
        /* Cuando se itere sobre la paleta de colores se la debería ajustar para poder usar 
        el lightgray fuera de la condicion disabled (por el momento se lo saca de disabledBackground
        en componentes que no estan en condicion de disabled). */
        backgroundColor: getThemeStyleState(theme, StyleVariant.Primary)
          .disabledBackground,
        opacity: 1,
        "$checked$checked + &": {
          backgroundColor: getThemeStyleState(theme, StyleVariant.Primary)
            .disabledBackground,
          opacity: 1
        },
        "$disabled$disabled + &": {
          backgroundColor: getThemeStyleState(theme, StyleVariant.Primary)
            .disabledBackground,
          opacity: 1
        }
      }
    }
  };
};
