import { Optional } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { getModelOrUndefined } from "model/primitives";
import { getFullNameFromPerson } from "model/resource/person";
import { Account } from "./account";

export const getAccountPayerNameOrUndefined = (
  account?: Account
): Optional<string> => {
  return getModelOrUndefined(account?.payer)?.name;
};

export const getAccountPatientNameOrUndefined = (
  account?: Account
): Optional<string> => {
  const fullName = getFullNameFromPerson(getModelOrUndefined(account?.patient));
  return !isEmpty(fullName) ? fullName : undefined;
};

export const getAccountNameOrUndefined = (
  account?: Account
): Optional<string> => {
  return account?.name;
};
