import { AxiosRequestConfig } from "axios";
import { Page } from "request/models/page";
import { DefaultError } from "./error";

export interface RequestConfig extends AxiosRequestConfig {
  useLongTimeout?: boolean;
}

export enum RequestFailureStatus {
  Failure = "Failure",
  Success = "Success"
}

interface RequestBaseResponse<T> {
  failureStatus: RequestFailureStatus;
  data?: T;
  status?: number;
}

export interface RequestSuccessResponse<R> extends RequestBaseResponse<R> {
  failureStatus: RequestFailureStatus.Success;
  data: R;
  status: number;
  headers?: Record<string, string>;
}

export interface RequestFailureResponse<E = DefaultError>
  extends RequestBaseResponse<E | undefined> {
  failureStatus: RequestFailureStatus.Failure;
  errorMsg: string;
}

export type RequestResponse<R, E = DefaultError> =
  | RequestSuccessResponse<R>
  | RequestFailureResponse<E>;

export type ApiPageRequestResponseBase<R, E = unknown> = RequestResponse<
  Page<R>,
  E
>;

export const createBaseSuccessMockApiRequestResponsePromise = <T, E = unknown>(
  data: T
): Promise<RequestResponse<T, E>> => {
  return Promise.resolve({
    failureStatus: RequestFailureStatus.Success,
    data,
    status: 200
  });
};
