import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const SensorIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="sensors"
        d="M4.925 19.075C4.025 18.1584 3.3125 17.0959 2.7875 15.8875C2.2625 14.6792 2 13.3834 2 12C2 10.6 2.2625 9.29588 2.7875 8.08755C3.3125 6.87922 4.025 5.82505 4.925 4.92505L6.35 6.35005C5.61667 7.08338 5.04167 7.93338 4.625 8.90005C4.20833 9.86672 4 10.9 4 12C4 13.1167 4.20833 14.1584 4.625 15.125C5.04167 16.0917 5.61667 16.9334 6.35 17.65L4.925 19.075ZM7.75 16.25C7.21667 15.7 6.79167 15.0625 6.475 14.3375C6.15833 13.6125 6 12.8334 6 12C6 11.15 6.15833 10.3625 6.475 9.63755C6.79167 8.91255 7.21667 8.28338 7.75 7.75005L9.175 9.17505C8.80833 9.54172 8.52083 9.96672 8.3125 10.45C8.10417 10.9334 8 11.45 8 12C8 12.55 8.10417 13.0667 8.3125 13.55C8.52083 14.0334 8.80833 14.4584 9.175 14.825L7.75 16.25ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0209 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1959 11.45 10 12 10C12.55 10 13.0208 10.1959 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0209 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM16.25 16.25L14.825 14.825C15.1917 14.4584 15.4792 14.0334 15.6875 13.55C15.8958 13.0667 16 12.55 16 12C16 11.45 15.8958 10.9334 15.6875 10.45C15.4792 9.96672 15.1917 9.54172 14.825 9.17505L16.25 7.75005C16.7833 8.28338 17.2083 8.91255 17.525 9.63755C17.8417 10.3625 18 11.15 18 12C18 12.8334 17.8417 13.6125 17.525 14.3375C17.2083 15.0625 16.7833 15.7 16.25 16.25ZM19.075 19.075L17.65 17.65C18.3833 16.9167 18.9583 16.0667 19.375 15.1C19.7917 14.1334 20 13.1 20 12C20 10.8834 19.7917 9.84172 19.375 8.87505C18.9583 7.90838 18.3833 7.06672 17.65 6.35005L19.075 4.92505C19.975 5.82505 20.6875 6.87922 21.2125 8.08755C21.7375 9.29588 22 10.6 22 12C22 13.3834 21.7375 14.6792 21.2125 15.8875C20.6875 17.0959 19.975 18.1584 19.075 19.075Z"
        fill="#4CB383"
      />
    </SvgIcon>
  );
};
