import {
  BaseVersionedModel,
  getKeyObj,
  ListQueryParams
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { keys } from "lodash-es";

export enum WhatsappClientOrder {
  CreationDate = "creationDate",
  UpdateDate = "lastUpdatedDate"
}

export enum WhatsappClientConnectionStatus {
  Pending = "Pending",
  Failed = "Failed",
  Connected = "Connected",
  Disconnected = "Disconnected",
  Revoked = "Revoked"
}

export interface WhatsappClient extends BaseVersionedModel {
  senderId?: string;
  organizationId?: string;
  phone?: string;
  enabled?: boolean;
  connectionStatus?: WhatsappClientConnectionStatus;
  backupEnabled?: boolean;
}

export type WhatsappClientLastVersion = WhatsappClient;

export const WhatsappClientParamsKey = getKeyObj<WhatsappClient>(
  createHydratedMock<WhatsappClient>()
);

export const WhatsappClientKeysList = keys(WhatsappClientParamsKey);

export interface WhatsappClientListQueryParams extends ListQueryParams {
  connectionStatus?: string;
  enabled?: boolean;
  organizationId?: string;
  phone?: string;
  senderId?: string;
  order?: WhatsappClientOrder;
}

interface WhatsappClientBaseRequest {
  senderId?: string;
}

export interface ConnectWhatsappClientRequest
  extends WhatsappClientBaseRequest {
  organizationId?: string;
}

export type DisconnectWhatsappClientRequest = WhatsappClientBaseRequest;
export type ConnectStopWhatsappClientRequest = WhatsappClientBaseRequest;

export interface WhatsappConfigureBackUpClientRequest
  extends WhatsappClientBaseRequest {
  enable?: boolean;
}
