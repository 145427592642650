import { Optional } from "@laba/ts-common";
import { ScheduleNotificationConfig } from "model/resource";
import { AppointmentActionData } from "./appointment";

export const getAppointmentActionDataPatientNotificationConfig = (
  appointmentActionData?: AppointmentActionData
): Optional<ScheduleNotificationConfig> => {
  return appointmentActionData?.notificationConfig?.patient;
};

export const getAppointmentActionDataPractitionerNotificationConfig = (
  appointmentActionData?: AppointmentActionData
): Optional<ScheduleNotificationConfig> => {
  return appointmentActionData?.notificationConfig?.practitioner;
};

export const getAppointmentActionDataPatientNotificationConfigCodeList = (
  appointmentActionData?: AppointmentActionData
): string[] =>
  getAppointmentActionDataPatientNotificationConfig(appointmentActionData)
    ?.codeList ?? [];

export const getAppointmentActionDataPractitionerNotificationConfigCodeList = (
  appointmentActionData?: AppointmentActionData
): string[] =>
  getAppointmentActionDataPractitionerNotificationConfig(appointmentActionData)
    ?.codeList ?? [];

export const getAppointmentActionDataPractitionerNotificationConfigNotifyActive =
  (appointmentActionData?: AppointmentActionData): boolean =>
    getAppointmentActionDataPractitionerNotificationConfig(
      appointmentActionData
    )?.active ?? false;

export const getAppointmentActionDataPractitionerNotificationConfigNotifyEmail =
  (appointmentActionData?: AppointmentActionData): boolean =>
    getAppointmentActionDataPractitionerNotificationConfig(
      appointmentActionData
    )?.notifyEmail ?? false;

export const getAppointmentActionDataPractitionerNotificationConfigNotifyPhone =
  (appointmentActionData?: AppointmentActionData): boolean =>
    getAppointmentActionDataPractitionerNotificationConfig(
      appointmentActionData
    )?.notifyPhone ?? false;

export const getAppointmentActionDataPatientNotificationConfigNotifyEmail = (
  appointmentActionData?: AppointmentActionData
): boolean =>
  getAppointmentActionDataPatientNotificationConfig(appointmentActionData)
    ?.notifyEmail ?? false;

export const getAppointmentActionDataPatientNotificationConfigNotifyPhone = (
  appointmentActionData?: AppointmentActionData
): boolean =>
  getAppointmentActionDataPatientNotificationConfig(appointmentActionData)
    ?.notifyPhone ?? false;
