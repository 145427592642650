import { IFrame, IMessage, StompHeaders } from "@stomp/stompjs";
import { GenericObject } from "model/types";

export type WebSocketClientConnectHeaders = StompHeaders;
export type WebSocketClientMessage = IMessage;

export type WebsocketClientDisconnectIFrame = IFrame;

export type SubscribeDestinations = string;
export type PublishDestinations = string;

export interface WebSocketClientGetterProps {
  brokerURL: string;
  connectionTimeout?: number;
  onConnectionReady?: () => void;
  onError?: (error: Error, type?: ErrorType) => void;
  onDisconnect?: (frame: WebsocketClientDisconnectIFrame) => void;
  debugger?: (msg: string) => void;
}

export interface SubscriptionObject<T> {
  path: SubscribeDestinations;
  callback: (message: T) => Promise<void>;
  headers?: WebSocketClientConnectHeaders;
}

export type SubscriptionObjectMap<T> = Record<string, SubscriptionObject<T>>;

export interface WebSocketClient {
  init: (header: WebSocketClientConnectHeaders) => void;
  disconnect: () => void;
  subscribe: <T>(subscription: SubscriptionObject<T>) => void;
  sendMessage: (
    destination: PublishDestinations,
    body: GenericObject,
    headers?: WebSocketClientConnectHeaders
  ) => void;
  isConnected: () => boolean;
}

export type WebsocketClientGetter = () => WebSocketClient;

export type ErrorType =
  | ErrorCode.StompError
  | ErrorCode.WebSocketError
  | ErrorCode.ConnectionError
  | ErrorCode.SubscriptionError
  | ErrorCode.PublishError;

export enum ErrorCode {
  WebSocketError = "WebSocketError",
  StompError = "StompError",
  ConnectionError = "ConnectionError",
  SubscriptionError = "SubscriptionError",
  PublishError = "PublishError"
}
