import {
  FC,
  getClassName,
  IconC,
  SizeVariant,
  StyleVariant,
  TextVariant,
  TypeVariant
} from "@laba/react-common";
import React from "react";
import { CardVariant } from "components/card/cardVariants";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { Divider, DividerSize } from "components/divider/Divider";
import { IconButtonType } from "components/buttons/IconButton/IconButton";
import {
  ButtonType,
  DefaultButton
} from "components/buttons/DefaultButton/DefaultButton";
import { Noop, notUndefined } from "@laba/ts-common";
import { ColorTagList } from "components/tags/ColorTagList/ColorTagList";
import { ColorTagItem } from "components/tags/ColorTag/ColorTag";
import { useDynamicTagList } from "hooks";
import { HeaderCreationOrLastEditDateText } from "components/headers/ResourceDetailHeader/HeaderCreationOrLastEditDateText/HeaderCreationOrLastEditDateText";
import { ResourceDateAndPractitioner } from "components/headers/ResourceDetailHeader/HeaderCreationOrLastEditDateText/utils";
import {
  useCardGenericHeaderStyleClasses,
  useMuiLeftIconStyleClasses,
  useMuiRightIconStyleClasses
} from "./GenericCardHeaderStyle";

export interface GenericCardHeaderProps {
  className?: string;
  headerTitle?: string;
  headerTitleTextVariant?: TextVariant;
  headerColor?: string;
  LeftIconHeader?: IconC;
  RightIconHeader?: IconC;
  onRightIconClick?: Noop;
  ExtraRightIconHeader?: IconC;
  onExtraRightIconClick?: Noop;
  extraRightIconHeaderButtonType?: IconButtonType;
  cardVariant?: CardVariant;
  primaryStyle?: StyleVariant;
  disableRightIcon?: boolean;
  disableExtraRightIcon?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  formId?: string;
  isMobile?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  showSecondaryButton?: boolean;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  hideUpperPrimaryButtons?: boolean;
  tags?: ColorTagItem[];
  lastEditData?: ResourceDateAndPractitioner;
  creationData?: ResourceDateAndPractitioner;
  hide?: boolean;
}

export const GenericCardHeader: FC<GenericCardHeaderProps> = ({
  className,
  headerTitle,
  headerTitleTextVariant,
  headerColor,
  LeftIconHeader,
  RightIconHeader,
  onRightIconClick,
  ExtraRightIconHeader,
  onExtraRightIconClick,
  cardVariant = CardVariant.Horizontal,
  primaryStyle = StyleVariant.Primary,
  disableRightIcon = false,
  disableExtraRightIcon = false,
  loading = false,
  fullWidth = true,
  formId,
  onPrimaryButtonClick,
  primaryButtonText,
  PrimaryButtonIcon,
  disablePrimaryButton,
  isMobile,
  onSecondaryButtonClick,
  secondaryButtonText,
  disableSecondaryButton,
  SecondaryButtonIcon,
  hideUpperPrimaryButtons = false,
  tags = [],
  lastEditData,
  creationData,
  showSecondaryButton = true,
  hide = false
}) => {
  const muiLeftIconClasses = useMuiLeftIconStyleClasses({
    style: StyleVariant.Success,
    cardVariant
  });
  const muiRightIconClasses = useMuiRightIconStyleClasses({
    style: primaryStyle
  });
  const classes = useCardGenericHeaderStyleClasses({
    cardVariant,
    headerBorderColor: headerColor,
    style: primaryStyle,
    fullWidth,
    headerTitleTextVariant,
    isMobile
  });

  const showExtraRightIcon =
    Boolean(ExtraRightIconHeader) &&
    (Boolean(onExtraRightIconClick) || notUndefined(formId));

  const showRightIcon = Boolean(RightIconHeader) && Boolean(onRightIconClick);

  const showPrimaryAction =
    notUndefined(onPrimaryButtonClick) || notUndefined(formId);

  const showSecondaryAction =
    notUndefined(onSecondaryButtonClick) && showSecondaryButton;

  const showActions =
    showRightIcon ||
    showExtraRightIcon ||
    showPrimaryAction ||
    showSecondaryAction;

  const showActionIcon = hideUpperPrimaryButtons || isMobile;

  const { ref, tagsList } = useDynamicTagList(tags);

  const RightIconsContainer = showActions ? (
    <div className={classes.rightIconContainer}>
      {showExtraRightIcon && showActionIcon && ExtraRightIconHeader && (
        <BaseIconButton
          className={muiRightIconClasses.root}
          onClick={onExtraRightIconClick}
          disabled={disableExtraRightIcon || loading}
          Icon={ExtraRightIconHeader}
          iconButtonType={
            notUndefined(formId) ? IconButtonType.Submit : IconButtonType.Button
          }
          formId={formId}
        />
      )}
      {showSecondaryAction && !hideUpperPrimaryButtons && !isMobile && (
        <DefaultButton
          onClick={onSecondaryButtonClick}
          text={secondaryButtonText}
          title={secondaryButtonText}
          size={SizeVariant.Small}
          type={TypeVariant.Outlined}
          style={StyleVariant.Primary}
          StartIcon={SecondaryButtonIcon}
          disabled={disableSecondaryButton || loading}
        />
      )}
      {showRightIcon && showActionIcon && RightIconHeader && (
        <BaseIconButton
          className={muiRightIconClasses.root}
          onClick={onRightIconClick}
          disabled={disableRightIcon || loading}
          Icon={RightIconHeader}
          style={primaryStyle}
        />
      )}
      {showPrimaryAction && !hideUpperPrimaryButtons && !isMobile && (
        <DefaultButton
          onClick={onPrimaryButtonClick}
          text={primaryButtonText}
          title={primaryButtonText}
          size={SizeVariant.Small}
          style={StyleVariant.Primary}
          StartIcon={PrimaryButtonIcon}
          formId={formId}
          buttonType={formId ? ButtonType.Submit : ButtonType.Button}
          disabled={disablePrimaryButton || loading}
        />
      )}
    </div>
  ) : null;

  return !hide ? (
    <div className={classes.root}>
      <div className={getClassName(classes.header, className)}>
        {LeftIconHeader && <LeftIconHeader classes={muiLeftIconClasses} />}
        <div className={classes.dataWrapper}>
          <div className={classes.textsAndIconsWrapper}>
            <div className={classes.textsWrapper}>
              <div className={classes.titleAndTags}>
                {headerTitle && (
                  <div className={classes.headerTitle}>{headerTitle}</div>
                )}
                <ColorTagList ref={ref} tags={tagsList} />
              </div>
              {!isMobile && (
                <HeaderCreationOrLastEditDateText
                  creationData={creationData}
                  lastEditData={lastEditData}
                />
              )}
            </div>
            {isMobile && RightIconsContainer}
          </div>
          {isMobile && (
            <HeaderCreationOrLastEditDateText
              creationData={creationData}
              lastEditData={lastEditData}
            />
          )}
        </div>
        {!isMobile && RightIconsContainer}
      </div>
      <Divider size={DividerSize.Large} />
    </div>
  ) : null;
};
