import { head, isUndefined } from "lodash-es";
import { DateTime, Optional } from "@laba/ts-common";
import { getModelOrUndefined } from "model/primitives";
import { Organization } from "model/resource/entities";
import { KnownScheduleType, Schedule } from "model/resource/schedule";
import {
  ScheduleActionWindow,
  ScheduleNotificationConfig,
  ScheduleNotificationConfigObj
} from "./schedule";
import { ScheduleDefinition } from "./scheduleDefinition";
import {
  AppointmentNotificationConfigCodes,
  appointmentNotificationConfigCodesHasCode
} from "../appointment";

export const getFirstScheduleSpeciality = (
  schedule?: Schedule
): Optional<string> => {
  return head(schedule?.speciality);
};

export const getScheduleEndDateTime = (
  schedule?: Schedule
): Optional<DateTime> => {
  const endDate = schedule?.planningHorizon?.end;
  return DateTime.fromApiDateOrUndefined(endDate)?.startOf("day");
};

export const getScheduleEndDateFixed = (
  schedule?: Schedule
): Optional<DateTime> => {
  return getScheduleEndDateTime(schedule)?.minus({ days: 1 });
};

export const getScheduleStartDateTime = (
  schedule?: Schedule
): Optional<DateTime> => {
  const endDate = schedule?.planningHorizon?.start;
  return DateTime.fromApiDateOrUndefined(endDate)?.startOf("day");
};

export const isScheduleVirtual = (schedule?: Schedule): boolean => {
  return schedule?.type === KnownScheduleType.VideoChat;
};

export const isSchedulePresential = (schedule?: Schedule): boolean => {
  return schedule?.type === KnownScheduleType.Presential;
};

export const isScheduleActionWindowNotUndefined = (
  window?: ScheduleActionWindow
): boolean => {
  const windowStart = window?.start;
  const windowEnd = window?.end;
  return (
    !isUndefined(window) &&
    (!isUndefined(windowStart) || !isUndefined(windowEnd))
  );
};

export const hasScheduleConfirmConfig = (schedule?: Schedule): boolean => {
  return isScheduleActionWindowNotUndefined(schedule?.confirmationWindow);
};

export const getScheduleOrganization = (
  schedule?: Schedule
): Optional<Organization> => getModelOrUndefined(schedule?.organization);

export const getSchedulePatientInstructions = (
  schedule?: Schedule
): Optional<string> => schedule?.patientInstruction;

const getScheduleNotificationConfig = (
  schedule?: Schedule
): Optional<ScheduleNotificationConfigObj> => schedule?.notificationConfig;

const getSchedulePatientNotificationConfig = (
  schedule?: Schedule
): Optional<ScheduleNotificationConfig> =>
  getScheduleNotificationConfig(schedule)?.patient;

const getSchedulePractitionerNotificationConfig = (
  schedule?: Schedule
): Optional<ScheduleNotificationConfig> =>
  getScheduleNotificationConfig(schedule)?.practitioner;

const getScheduleDefinitionNotificationConfig = (
  scheduleDefinition?: ScheduleDefinition
): Optional<ScheduleNotificationConfigObj> =>
  scheduleDefinition?.notificationConfig;

const getScheduleDefinitionPractitionerNotificationConfig = (
  scheduleDefinition?: ScheduleDefinition
): Optional<ScheduleNotificationConfig> =>
  getScheduleDefinitionNotificationConfig(scheduleDefinition)?.practitioner;

const getScheduleDefinitionPatientNotificationConfig = (
  scheduleDefinition?: ScheduleDefinition
): Optional<ScheduleNotificationConfig> =>
  getScheduleDefinitionNotificationConfig(scheduleDefinition)?.patient;

export const scheduleDefinitionNotificationConfigCodeListHasCode = (
  code: AppointmentNotificationConfigCodes,
  isPractitioner: boolean,
  scheduleDefinition?: ScheduleDefinition
): boolean => {
  if (isPractitioner) {
    const practitionerNotificationConfig =
      getScheduleDefinitionPractitionerNotificationConfig(scheduleDefinition);
    return appointmentNotificationConfigCodesHasCode(
      code,
      practitionerNotificationConfig?.codeList
    );
  }

  const patientNotificationConfig =
    getScheduleDefinitionPatientNotificationConfig(scheduleDefinition);
  return appointmentNotificationConfigCodesHasCode(
    code,
    patientNotificationConfig?.codeList
  );
};

export const scheduleNotificationConfigCodeListHasCode = (
  code: AppointmentNotificationConfigCodes,
  isPractitioner: boolean,
  schedule?: Schedule
): boolean => {
  if (isPractitioner) {
    const practitionerNotificationConfig =
      getSchedulePractitionerNotificationConfig(schedule);
    return appointmentNotificationConfigCodesHasCode(
      code,
      practitionerNotificationConfig?.codeList
    );
  }

  const patientNotificationConfig =
    getSchedulePatientNotificationConfig(schedule);
  return appointmentNotificationConfigCodesHasCode(
    code,
    patientNotificationConfig?.codeList
  );
};
