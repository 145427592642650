import {
  FC,
  Form,
  FormErrorData,
  FormValidator,
  ModelId,
  useI18n
} from "@laba/react-common";
import React, { useCallback, useState } from "react";
import {
  ButtonType,
  DefaultButton,
  SpinnerDialog
} from "@laba/nexup-components";
import { tkCP } from "translation/i18n";
import { useAppDispatch } from "store/store";
import { isEmpty } from "lodash-es";
import { onSaveWorkspaceConfig } from "store/dashboard/event";
import { WorkspaceConfigData } from "models/organization/workspaceConfiguration";
import { Grid } from "@mui/material";
import { defaultWorkspaceOrganizationIdSelector } from "store/workspace/selectors";
import { useSelector } from "react-redux";
import { Code, Concept, KnownCodeSystemSystem } from "@laba/nexup-api";
import { useCodeSystemGroup } from "components/hook/useCodeSystem";
import { Optional } from "@laba/ts-common";
import { WorkspaceAdministrationFormContent } from "./WorkspaceAdministrationFormContent";

export interface WorkspaceAdministrationInputProps {
  onEditionFinished?: (success: boolean, error?: Error) => void;
}

const tk = tkCP.adminPage.dashboard.workspaceAdministration;
const formId = "WorkspaceAdministrationForm";
const defaultInitialValues: WorkspaceConfigData = {};
const formValidation: FormValidator<WorkspaceConfigData> = (t, formData) => {
  const errors: FormErrorData<WorkspaceConfigData> = {};

  if (formData.parentOrganization == null) {
    errors.parentOrganization = t(tk.validation.requiredField);
  }
  if (isEmpty(formData.organizationName)) {
    errors.organizationName = t(tk.validation.requiredField);
  }
  if (isEmpty(formData.organizationCrmId)) {
    errors.organizationCrmId = t(tk.validation.requiredField);
  }
  if (isEmpty(formData.organizationPlan)) {
    errors.organizationPlan = t(tk.validation.requiredField);
  }
  if (isEmpty(formData.organizationPaymentStatus)) {
    errors.organizationPaymentStatus = t(tk.validation.requiredField);
  }

  return isEmpty(errors) ? undefined : errors;
};

const useGetPractitionerRolesConceptGetterByCode = (
  organizationId?: ModelId
) => {
  const practitionerRoleSystem = useCodeSystemGroup(
    KnownCodeSystemSystem.PractitionerRole,
    organizationId
  );
  return useCallback(
    (code: Code): Optional<Concept> => {
      return practitionerRoleSystem?.concept?.find(c => c.code === code);
    },
    [practitionerRoleSystem]
  );
};

export const WorkspaceAdministrationInput: FC<
  WorkspaceAdministrationInputProps
> = ({ onEditionFinished }) => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const defaultWorkspaceOrganizationId = useSelector(
    defaultWorkspaceOrganizationIdSelector
  );

  const practitionerRoleConceptGetter =
    useGetPractitionerRolesConceptGetterByCode(defaultWorkspaceOrganizationId);

  const onSubmit = useCallback(
    async (requestData: WorkspaceConfigData) => {
      setLoading(true);
      const error = await dispatch(
        onSaveWorkspaceConfig(requestData, practitionerRoleConceptGetter)
      );
      setLoading(false);
      onEditionFinished?.(error == null, error);
    },
    [setLoading, onEditionFinished, dispatch, practitionerRoleConceptGetter]
  );
  const validate = useCallback(
    (data: WorkspaceConfigData) => formValidation(t, data),
    [t]
  );
  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item sm={12} alignSelf="center">
          <Form<WorkspaceConfigData>
            id={formId}
            initialValues={defaultInitialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            <WorkspaceAdministrationFormContent setLoading={setLoading} />
          </Form>
        </Grid>
        <Grid item sm={12} alignSelf="center">
          <DefaultButton
            title={t(tk.save)}
            text={t(tk.save)}
            formId={formId}
            buttonType={ButtonType.Submit}
            disabled={loading}
            fullWidth
          />
        </Grid>
      </Grid>
      <SpinnerDialog open={loading} />
    </>
  );
};
