import {
  Color,
  FC,
  getClassName,
  IconC,
  TextVariant
} from "@laba/react-common";
import React from "react";
import { CircleIcon } from "components/icons";
import { NexupColor } from "model/nexupColor";
import { useIconWithTextStyles } from "./useIconWithTextStyles";

export interface IconWithTextProps {
  className?: string;
  color?: Color;
  text?: string;
  reverseOrder?: boolean;
  textVariant?: TextVariant;
  title?: string;
  notShrinkText?: boolean;
  Icon?: IconC;
  iconClassName?: string;
}

export const IconWithText: FC<IconWithTextProps> = ({
  className,
  text,
  reverseOrder = false,
  textVariant = TextVariant.Caption,
  title,
  notShrinkText = false,
  Icon = CircleIcon,
  iconClassName,
  color = iconClassName ? undefined : NexupColor.DarkGray
}) => {
  const classes = useIconWithTextStyles({
    reverseOrder,
    textVariant,
    notShrinkText
  });
  return (
    <div className={getClassName(className, classes.container)} title={title}>
      <Icon style={{ color }} className={iconClassName} titleAccess={title} />
      {text && (
        <p className={classes.text} title={title ?? text}>
          {text}
        </p>
      )}
    </div>
  );
};
