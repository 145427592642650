import { getKeyObj, Optional } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { OpenCode } from "model/primitives/model/model";
import { Code } from "model/resource/entities/codeSystem";
import { Attachment } from "./attachment/attachment";
import { ResourceModel, ResourceType } from "./resourceModel";

export enum KnownIdentifierSystem {
  Dni = "http://www.renaper.gob.ar/dni",
  Passport = "http://fhir.msal.gov.ar/NamingSystem/passport",
  Nexup = "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-nexup-identifier",
  Cuit = "http://platform.lab-a.com.ar/fhir/StructureDefinition/system-cuit",
  HealthcareId = "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-healthcare-id",
  HisCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/his-code",
  PractitionerSignature = "http://platform.lab-a.com.ar/fhir/StructureDefinition/practitioner-signature",
  Ci = "https://dnic.minterior.gub.uy/ci",
  Rut = "https://www.dgi.gub.uy/rut",
  MedicalRecordId = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medical-record-id",
  NationalNomenclature = "http://platform.lab-a.com.ar/fhir/StructureDefinition/national-nomenclature",
  NBUNomenclature = "http://platform.lab-a.com.ar/fhir/StructureDefinition/nbu-nomenclature",
  HPGDNomenclature = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hpgd-nomenclature",
  SnomedNomenclature = "http://platform.lab-a.com.ar/fhir/StructureDefinition/snomed-nomenclature",
  OrganizationPayerCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-payer-code",
  OrganizationCRMId = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-crm-id",
  ProductCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/product-code",
  PractitionerQualificationRegistration = "http://platform.lab-a.com.ar/fhir/StructureDefinition/practitioner-qualification-registration",
  HospitalizationAuthorization = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-authorization",
  HospitalizationConsent = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-consent"
}

export const LegalIdentifierImportanceOrder: Record<
  KnownIdentifierSystem,
  Optional<number>
> = {
  [KnownIdentifierSystem.Dni]: 0,
  [KnownIdentifierSystem.Cuit]: 1,
  [KnownIdentifierSystem.Passport]: 2,
  [KnownIdentifierSystem.Rut]: 3,
  [KnownIdentifierSystem.Ci]: 4,
  [KnownIdentifierSystem.HisCode]: undefined,
  [KnownIdentifierSystem.HealthcareId]: undefined,
  [KnownIdentifierSystem.Nexup]: undefined,
  [KnownIdentifierSystem.PractitionerSignature]: undefined,
  [KnownIdentifierSystem.MedicalRecordId]: undefined,
  [KnownIdentifierSystem.NationalNomenclature]: undefined,
  [KnownIdentifierSystem.NBUNomenclature]: undefined,
  [KnownIdentifierSystem.HPGDNomenclature]: undefined,
  [KnownIdentifierSystem.SnomedNomenclature]: undefined,
  [KnownIdentifierSystem.OrganizationPayerCode]: undefined,
  [KnownIdentifierSystem.OrganizationCRMId]: undefined,
  [KnownIdentifierSystem.ProductCode]: undefined,
  [KnownIdentifierSystem.PractitionerQualificationRegistration]: undefined,
  [KnownIdentifierSystem.HospitalizationAuthorization]: undefined,
  [KnownIdentifierSystem.HospitalizationConsent]: undefined
};

export enum IdentifierPropertyType {
  FederationVersion = "FederationVersion",
  MedicationPresentation = "MedicationPresentation",
  MedicationDiscount = "MedicationDiscount",
  NomenclatureBook = "NomenclatureBook",
  Picture = "Picture",
  Plan = "Plan",
  SubscriptionStatus = "SubscriptionStatus",
  SubscriptionExpiration = "SubscriptionExpiration"
}

export type IdentifierSystem = OpenCode<KnownIdentifierSystem>;

export interface IdentifierProperty {
  type?: IdentifierPropertyType;
  valueString?: string;
  valueDecimal?: number;
  valueBoolean?: boolean;
  valueCode?: Code;
}

export const IdentifierPropertyKey = getKeyObj(
  createHydratedMock<IdentifierProperty>()
);
export interface Identifier extends ResourceModel<ResourceType.Identifier> {
  value?: string;
  system: IdentifierSystem;
  files?: Attachment[];
  property?: IdentifierProperty[];
}

export const IdentifierKey = getKeyObj(createHydratedMock<Identifier>());

export type KnownIdentifierSystemType = keyof typeof KnownIdentifierSystem;
