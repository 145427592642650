import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface WhatsappClientSessionData {
  id: string;
  qr?: string;
  phone?: string;
  status?: WhatsappClientSessionStatus;
  whatsappWebVersion?: string;
}

export enum WhatsappClientSessionStatus {
  AwaitingConnection = "AwaitingConnection",
  Connected = "Connected",
  Stopped = "Stopped",
  Disconnected = "Disconnected",
  Failed = "Failed"
}

export const WhatsappClientSessionDataParamsKey =
  getKeyObj<WhatsappClientSessionData>(
    createHydratedMock<WhatsappClientSessionData>()
  );
