import { Event } from "store/store";
import {
  getResponseFilename,
  logger,
  Optional,
  RequestFailureStatus,
  sleep
} from "@laba/ts-common";
import {
  AlfabetaMode,
  alfabetaRequest,
  AlfabetaRequestBody,
  finnegansFeeIntegrationMaintenanceRequest,
  FinnegansFeeIntegrationRequestBody,
  getPractitionerRoleList,
  getWhatsappSessionList,
  importPatientRequest,
  importPractitionerRequest,
  importQuestionnaireResponseRequest,
  ImportQuestionnaireResponseRequestBody,
  ImportRequestBody,
  importVademecumRequest,
  ImportVademecumRequestBody,
  maintenanceCompleteConfigurationRequest,
  maintenanceCreatePrescriptionDocumentRequest,
  maintenanceStartSyncRequest,
  MaintenanceSyncStatus,
  maintenanceSyncStatusRequest,
  ModelId,
  PractitionerRole,
  runNotificationProcessQueue,
  runWhatsappNotificationProcessQueue,
  santaCatalinaPayrollAllocationListRequest,
  santaCatalinaPayrollRequest,
  SantaCatalinaPayrollRequestBody,
  scrapeResourceListEndpoint,
  SyncResourceBody,
  updatePractitionerRole,
  WhatsappClientSessionData,
  regenerateAppointmentNotifications,
  RegenerateAppointmentNotificationsRequestBody,
  Concept
} from "@laba/nexup-api";
import {
  organizationConfigurationFromWorkspaceConfigData,
  WorkspaceConfigData
} from "models/organization/workspaceConfiguration";
import { importOrganizationConfiguration } from "store/organization/event";
import { isError } from "lodash-es";
import produce from "immer";
import {
  defaultWorkspaceOrganizationIdSelector,
  workspaceListSelector,
  workspaceSelector
} from "store/workspace/selectors";
import { saveBlobToDevice } from "@laba/react-common";
import { organizationWorkspaceSkipDownloadConfig } from "common/constants/organizationWorkspaceSkipDownloadConfig";

export const onSantaCatalinaPayrollImport =
  (importData: SantaCatalinaPayrollRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      santaCatalinaPayrollRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onSantaCatalinaPayrollAllocationsImport =
  (importData: SantaCatalinaPayrollRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      santaCatalinaPayrollAllocationListRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onPatientImport =
  (importData: ImportRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      importPatientRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onVademecumImport =
  (importData: ImportVademecumRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      importVademecumRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onPractitionerImport =
  (importData: ImportRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      importPractitionerRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onMaintenanceRefresh =
  (): Event<Optional<MaintenanceSyncStatus>> => async () => {
    const result = await maintenanceSyncStatusRequest();
    if (result.failureStatus === RequestFailureStatus.Failure) return undefined;
    return result.data;
  };

export const onMaintenanceCompleteConfiguration =
  (): Event<boolean> => async () => {
    const result = await maintenanceCompleteConfigurationRequest({});
    return result.failureStatus === RequestFailureStatus.Success;
  };

export const onMaintenanceStartSync =
  (data: SyncResourceBody): Event<boolean> =>
  async () => {
    const result = await maintenanceStartSyncRequest(data);
    return result.failureStatus === RequestFailureStatus.Success;
  };

export const onSaveWorkspaceConfig =
  (
    data: WorkspaceConfigData,
    practitionerRolesConceptGetter: (code: ModelId) => Optional<Concept>
  ): Event<Optional<Error>> =>
  async (dispatch, getState) => {
    const nexupOrganizationId = defaultWorkspaceOrganizationIdSelector(
      getState()
    );
    const newOrganizationConfiguration =
      organizationConfigurationFromWorkspaceConfigData(data);
    const result = await dispatch(
      importOrganizationConfiguration(
        newOrganizationConfiguration,
        organizationWorkspaceSkipDownloadConfig,
        practitionerRolesConceptGetter,
        nexupOrganizationId
      )
    );
    if (isError(result)) return result;
  };

export const onMigrateRol =
  (oldRolList: string[], newRol: string): Event =>
  async () => {
    const practitionerRolList: PractitionerRole[] = [];
    for (let i = 0; i < oldRolList.length; i += 1) {
      const rolPractitionerRolList =
        // eslint-disable-next-line no-await-in-loop
        (await scrapeResourceListEndpoint(getPractitionerRoleList, {
          pageSize: 100,
          active: true,
          role: oldRolList[i]
        })) ?? [];
      practitionerRolList.push(...rolPractitionerRolList);
    }

    for (let i = 0; i < practitionerRolList.length; i += 1) {
      const practitionerRol = practitionerRolList[i];
      if (practitionerRol) {
        // eslint-disable-next-line no-await-in-loop
        const result = await updatePractitionerRole(
          produce(practitionerRol, draft => {
            draft.role = newRol;
          })
        );
        if (
          result.failureStatus === RequestFailureStatus.Failure &&
          result.data
        ) {
          logger.error(result.data);
        }
      }
    }
  };

export const onDownloadWhatsappSessionList =
  (): Event<WhatsappClientSessionData[]> => async () => {
    const response = await getWhatsappSessionList();
    if (response.failureStatus === RequestFailureStatus.Failure) {
      return [];
    }
    return response.data;
  };

export const onRunNotificationProcessQueueClicked = (): Event => async () => {
  const response = await runNotificationProcessQueue();
  if (response.failureStatus === RequestFailureStatus.Failure) {
    logger.error(response.data);
  }
};

export const onRunWhatsappNotificationProcessQueueClicked =
  (): Event => async () => {
    const response = await runWhatsappNotificationProcessQueue();
    if (response.failureStatus === RequestFailureStatus.Failure) {
      logger.error(response.data);
    }
  };

export const onCreatePrescriptionDocument =
  (prescriptionIdList: ModelId[]): Event =>
  async () => {
    for (let i = 0; i < prescriptionIdList.length; i += 1) {
      const prescriptionId = prescriptionIdList[i];
      if (prescriptionId) {
        // eslint-disable-next-line no-await-in-loop
        await maintenanceCreatePrescriptionDocumentRequest(prescriptionId);
      }
    }
  };

export const onQuestionnaireResponseImport =
  (importData: ImportQuestionnaireResponseRequestBody): Event<boolean> =>
  async () => {
    // Use sleep because request can last for longer than 10 mins.
    // If it doesn't end in the first 10 sec we can assume that it would be a succesfull response
    return Promise.race([
      importQuestionnaireResponseRequest(importData).then(
        value => value.failureStatus === RequestFailureStatus.Success
      ),
      sleep(10000).then(() => true)
    ]);
  };

export const onAlfabetaSyncProduct =
  (data: AlfabetaRequestBody): Event<boolean> =>
  async (_dispatch, getState) => {
    const workspaceList = workspaceListSelector(getState());
    const workspacePractitionerId = workspaceList.find(
      x => x.organization?.id === data.organizationId
    )?.practitioner?.id;
    const practitionerId =
      workspacePractitionerId ??
      workspaceSelector(getState())?.practitioner?.id;

    const response = await alfabetaRequest({
      ...data,
      mode: AlfabetaMode.Product,
      practitionerId
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      return false;
    }
    const filename = getResponseFilename(
      response,
      "Medicamentos_Productos.xlsx"
    );
    saveBlobToDevice(response.data, filename);
    return true;
  };

export const onAlfabetaSyncMedication =
  (data: AlfabetaRequestBody): Event<boolean> =>
  async (_dispatch, getState) => {
    const workspaceList = workspaceListSelector(getState());
    const workspacePractitionerId = workspaceList.find(
      x => x.organization?.id === data.organizationId
    )?.practitioner?.id;
    const practitionerId =
      workspacePractitionerId ??
      workspaceSelector(getState())?.practitioner?.id;

    const response = await alfabetaRequest({
      ...data,
      mode: AlfabetaMode.Medication,
      practitionerId
    });
    return response.failureStatus !== RequestFailureStatus.Failure;
  };

export const onAlfabetaContractGeneration =
  (data: AlfabetaRequestBody): Event<boolean> =>
  async (_dispatch, getState) => {
    const workspaceList = workspaceListSelector(getState());
    const workspacePractitionerId = workspaceList.find(
      x => x.organization?.id === data.organizationId
    )?.practitioner?.id;
    const practitionerId =
      workspacePractitionerId ??
      workspaceSelector(getState())?.practitioner?.id;

    const response = await alfabetaRequest({
      ...data,
      mode: AlfabetaMode.Contract,
      practitionerId
    });
    if (response.failureStatus === RequestFailureStatus.Failure) {
      return false;
    }
    const filename = getResponseFilename(
      response,
      "Medicamentos_Convenio.xlsx"
    );
    saveBlobToDevice(response.data, filename);
    return true;
  };

export const onFinnegansFeeIntegrationSubmit =
  (data: FinnegansFeeIntegrationRequestBody): Event<boolean> =>
  async () => {
    const response = await finnegansFeeIntegrationMaintenanceRequest(data);
    return response.failureStatus !== RequestFailureStatus.Failure;
  };

export const onRegenerateAppointmentNotificationSubmit =
  (data: RegenerateAppointmentNotificationsRequestBody): Event<boolean> =>
  async () => {
    const { id, params } = data;
    const response = await regenerateAppointmentNotifications(id, params);
    return response.failureStatus !== RequestFailureStatus.Failure;
  };
