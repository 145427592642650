import React from "react";
import { WhatsappClientSessionData } from "@laba/nexup-api";
import { FC, useI18n } from "@laba/react-common";
import { Grid, Typography } from "@mui/material";
import { tkCP } from "translation/i18n";
import { NexupQR } from "@laba/nexup-components";

interface Props {
  session: WhatsappClientSessionData;
}
const tk = tkCP.adminPage.dashboard.whatsappSession.item;

export const WhatsappSessionItem: FC<Props> = ({ session }) => {
  const { t } = useI18n();

  return (
    <Grid container columnSpacing={10} rowSpacing={2}>
      <Grid item>
        <Typography>{t(tk.id, { id: session.id })}</Typography>
        <Typography>
          {t(tk.version, { version: session.whatsappWebVersion })}
        </Typography>
        <Typography>{t(tk.statusTitle, { status: session.status })}</Typography>
        <Typography>{t(tk.phoneNumber, { phone: session.phone })}</Typography>
      </Grid>
      {session.qr && (
        <Grid item>
          <NexupQR qr={session.qr} />
        </Grid>
      )}
    </Grid>
  );
};
