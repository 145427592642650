import { HttpMethod, ModelId } from "@laba/ts-common";
import { BackendError } from "model/resource";
import { ApiRequestResponse } from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { WhatsappClient } from "model/communication";

export const whatsappClientBasePath = "/whatsapp-client";

export const disconnectClient = async (
  organizationId: string
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request<WhatsappClient, BackendError>({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${whatsappClientBasePath}/disconnect`),
    data: { organizationId }
  });

export const configureWhatsappClientBackup = async (
  organizationId: ModelId,
  enable: boolean
): Promise<ApiRequestResponse<WhatsappClient>> =>
  request<WhatsappClient, BackendError>({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${whatsappClientBasePath}/backup`),
    data: { organizationId, enable }
  });
