import React from "react";
import { FC, TextVariant, TypeVariant } from "@laba/react-common";
import { ColorTagItem } from "components/tags/ColorTag/ColorTag";
import { ColorTagList } from "components/tags/ColorTagList/ColorTagList";
import { isEmpty } from "lodash-es";
import { notUndefined } from "@laba/ts-common";
import {
  PatientAllergies,
  PatientAllergiesProps,
  PatientDetailData,
  PatientDetailDataItem
} from "components/headers";
import { TagFlatData } from "components/tags";
import { usePatientDetailMobilePopupContentStyles } from "./usePatientDetailMobilePopupContentStyles";

export interface PatientDetailMobilePopupContentProps
  extends PatientAllergiesProps {
  patientDetailData?: PatientDetailData[];
  tags?: TagFlatData[];
  showAllergies?: boolean;
}

export const PatientDetailMobilePopupContent: FC<
  PatientDetailMobilePopupContentProps
> = ({ patientDetailData, tags, showAllergies, ...rest }) => {
  const classes = usePatientDetailMobilePopupContentStyles();

  const colorTags: ColorTagItem[] =
    tags
      ?.map(tag => ({
        text: tag.display ?? tag.code,
        title: tag.display ?? tag.code,
        backgroundColor: tag.color
      }))
      .filter(notUndefined) ?? [];

  return (
    <div className={classes.root}>
      <div className={classes.patientDetailData}>
        {!isEmpty(patientDetailData) &&
          patientDetailData?.map((data, idx) => (
            <PatientDetailDataItem
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className={classes.patientDetailDataItem}
              textList={data.textList}
              showSeparator={data.showSeparator}
              textVariant={TextVariant.Body2}
              isMobile
            />
          ))}

        {!isEmpty(colorTags) && (
          <ColorTagList
            className={classes.colorTagList}
            tags={colorTags}
            type={TypeVariant.Outlined}
          />
        )}
      </div>
      {showAllergies && (
        <PatientAllergies
          {...rest}
          className={classes.patientAllergies}
          textVariant={TextVariant.Caption}
          titleVariant={TextVariant.Caption}
        />
      )}
    </div>
  );
};
