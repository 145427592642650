import { Client } from "@stomp/stompjs";
import { forEach } from "lodash-es";
import { GenericObject, Optional } from "model/types";
import {
  PublishDestinations,
  WebSocketClient,
  WebSocketClientGetterProps,
  WebsocketClientGetter,
  ErrorCode,
  WebSocketClientConnectHeaders,
  WebSocketClientMessage,
  SubscriptionObject,
  SubscriptionObjectMap
} from "./types";

export const webSocketClientGetter = (
  props: WebSocketClientGetterProps
): WebsocketClientGetter => {
  let clientInstance: Optional<Client>;

  const subscriptions: SubscriptionObjectMap<unknown> = {};

  const subscribe = <T>(subscription: SubscriptionObject<T>) => {
    if (clientInstance && clientInstance.connected) {
      const subscribeCallback = (message: WebSocketClientMessage) => {
        try {
          const parsedResponseMessage = JSON.parse(message.body);
          return subscription.callback(parsedResponseMessage);
        } catch (e) {
          props.onError?.(e, ErrorCode.SubscriptionError);
        }
      };
      clientInstance.subscribe(
        subscription.path,
        subscribeCallback,
        subscription.headers
      );
    } else {
      throw new Error("Client is not connected");
    }
  };

  const subscribeAndStoreSubscription = <T>(
    subscription: SubscriptionObject<T>
  ) => {
    subscribe(subscription);
    subscriptions[subscription.path] =
      subscription as unknown as SubscriptionObject<unknown>;
  };

  const restoreSubscriptions = (): void => {
    if (clientInstance !== undefined && clientInstance.connected) {
      forEach(subscriptions, subscription => {
        subscribe(subscription);
      });
    }
  };

  const createAndConnectClient = (
    headers: WebSocketClientConnectHeaders
  ): void => {
    try {
      const client = new Client({
        brokerURL: props.brokerURL,
        connectHeaders: headers,
        connectionTimeout: props.connectionTimeout,
        onConnect: () => {
          props.onConnectionReady?.();
          restoreSubscriptions();
        },
        onWebSocketError: error => {
          props.onError?.(error, ErrorCode.WebSocketError);
        },
        onStompError: frame => {
          const error = new Error(frame.body);
          props.onError?.(error, ErrorCode.StompError);
        },
        onDisconnect: frame => {
          props.onDisconnect?.(frame);
        },
        debug: msg => props.debugger?.(msg)
      });

      clientInstance = client;
      client.activate();
    } catch (error) {
      props.onError?.(error, ErrorCode.ConnectionError);
    }
  };

  const initClient = (headers: WebSocketClientConnectHeaders): void => {
    try {
      if (!clientInstance) {
        createAndConnectClient(headers);
        return;
      }
      if (!clientInstance.active) {
        clientInstance.activate();
      }
    } catch (error) {
      props.onError?.(error, ErrorCode.ConnectionError);
    }
  };

  const disconnectClient = async (): Promise<void> => {
    if (clientInstance) {
      await clientInstance.deactivate();
    }
  };

  const sendMessageToDestination = (
    destination: PublishDestinations,
    body: GenericObject,
    headers?: WebSocketClientConnectHeaders
  ): void => {
    try {
      if (clientInstance && clientInstance.connected) {
        clientInstance.publish({
          destination,
          body: JSON.stringify(body),
          headers
        });
      } else {
        throw new Error("Client is not connected");
      }
    } catch (error) {
      props.onError?.(error, ErrorCode.PublishError);
    }
  };

  const isClientConnected = (): boolean => {
    return clientInstance?.connected ?? false;
  };

  const webSocketClient: WebSocketClient = {
    init: initClient,
    disconnect: disconnectClient,
    subscribe: subscribeAndStoreSubscription,
    sendMessage: sendMessageToDestination,
    isConnected: isClientConnected
  };

  return () => {
    return webSocketClient;
  };
};
