import { isEqual } from "lodash-es";
import {
  ModelId,
  PayerConfiguration,
  PayerConfigurationType,
  PayerPlanConfiguration,
  PayerRulesConfiguration
} from "model/primitives";
import { Code } from "model/resource";

export const createBasePayerRulesConfiguration = (
  payerConfig?: PayerConfiguration[],
  type?: PayerConfigurationType
): PayerRulesConfiguration => {
  return { type, payerConfiguration: payerConfig };
};

export const createBasePayerConfiguration = (
  payer: ModelId,
  plan: PayerPlanConfiguration[] = []
): PayerConfiguration => {
  return { payer, planConfiguration: plan };
};

export const createBasePayerPlanConfiguration = (
  plan?: Code
): PayerPlanConfiguration => {
  return { plan };
};

export const isPayerConfigurationTypeOnlyPrivatePatient = (
  payerRulesConfiguration?: PayerRulesConfiguration
): boolean =>
  isEqual(
    payerRulesConfiguration?.type,
    PayerConfigurationType.OnlyPrivatePatient
  );
