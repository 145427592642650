import {
  createStyle,
  FlexAlignItems,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

const useIconWithTextDynamicStyles = createStyle((theme: Theme) => {
  return {
    text: (props: UseIconWithTextDynamicStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.textVariant,
        props.notShrinkText ? 1 : undefined
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    })
  };
}, "IconWithTextDynamic");

const useIconWithTextStaticStyles = createStyle((_theme: Theme) => {
  return {
    container: {
      ...flexRowMixin({ gap: 4, alignItems: FlexAlignItems.Center })
    },
    containerReverse: {
      ...flexRowMixin({
        gap: 4,
        alignItems: FlexAlignItems.Center,
        reverseOrder: true
      })
    },
    notShrinkText: {
      ...flexItemMixin({ shrink: 0 })
    }
  };
}, "IconWithTextStatic");

export interface UseIconWithTextDynamicStylesProps {
  reverseOrder: boolean;
  textVariant: TextVariant;
  notShrinkText: boolean;
}

export interface UseIconWithTextDynamicStylesReturn {
  container: string;
  text: string;
}

export const useIconWithTextStyles = (
  props: UseIconWithTextDynamicStylesProps
): UseIconWithTextDynamicStylesReturn => {
  const dynamicClasses = useIconWithTextDynamicStyles(props);
  const staticClasses = useIconWithTextStaticStyles();
  return {
    container: props.reverseOrder
      ? staticClasses.containerReverse
      : staticClasses.container,
    text: getClassName(
      dynamicClasses.text,
      props.notShrinkText ? staticClasses.notShrinkText : undefined
    )
  };
};
