import React from "react";
import { FCC } from "@laba/react-common";
import { Video } from "components/video/Video/Video";
import { Dialog, DialogProps } from "../Dialog/Dialog";

export interface DialogVideoProps extends DialogProps {
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  source: string;
  videoClassName?: string;
}

export const DialogVideo: FCC<DialogVideoProps> = ({
  className,
  videoClassName,
  autoPlay,
  loop,
  muted,
  controls,
  source,
  onClose,
  open = false,
  ...props
}) => {
  return (
    <Dialog onClose={onClose} open={open} className={className} {...props}>
      <Video
        className={videoClassName}
        autoPlay={autoPlay}
        loop={loop}
        muted={muted}
        controls={controls}
        src={source}
      />
    </Dialog>
  );
};
