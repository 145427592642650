import React, { useCallback, useState } from "react";
import { FC, getClassName, IconC } from "@laba/react-common";
import { Optional, Noop } from "@laba/ts-common";
import { countBy, floor, isEmpty, range, startsWith, uniq } from "lodash-es";
import {
  Autocomplete,
  AutocompleteOptionConfig
} from "components/autocomplete/Autocomplete/Autocomplete";
import { useTimePickerStyles } from "components/inputs/TimePicker/useTimePickerStyles";
import { AccessTime } from "@material-ui/icons";

export interface TimePickerHour {
  hour: number;
  minute: number;
}

const timePickerHourToString = (timePickerHour: TimePickerHour): string => {
  const hour = floor(timePickerHour.hour).toString().padStart(2, "0");
  const minute = floor(timePickerHour.minute).toString().padStart(2, "0");
  return `${hour}:${minute}`;
};

const stringToTimePickerHourOrUndefined = (
  text?: string
): Optional<TimePickerHour> => {
  if (!text || isEmpty(text)) return undefined;
  const numberList = text
    .split(":")
    .filter(hourText => !isEmpty(hourText) && hourText.length === 2);
  if (numberList.length !== 2) return undefined;
  const hour = Number(numberList[0]);
  const minute = Number(numberList[1]);

  if (hour >= 24 || minute >= 60) return undefined;
  return {
    hour,
    minute
  };
};

export interface TimePickerProps {
  className?: string;
  value?: TimePickerHour;
  onChange?: (h?: TimePickerHour) => void;
  placeholder?: string;
  EndIcon?: IconC;
  stepMinutes?: number;
  closeText?: string;
  clearText?: string;
  loadingText?: string;
  noOptionsText?: string;
  openText?: string;
  onBlur?: Noop;
  fullWidth?: boolean;
  clearable?: boolean;
  errorText?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  disabled?: boolean;
  endIconShouldNotRotate?: boolean;
}

const formatTimeText = (
  hour: number,
  minute: number,
  withMinutesPad = true
): string => {
  const minutesText = withMinutesPad
    ? String(minute).padStart(2, "0")
    : String(minute);
  return `${String(hour).padStart(2, "0")}:${minutesText}`;
};

const createTimeOptions = (
  step: number,
  value?: string
): AutocompleteOptionConfig<string>[] => {
  const totalMinutesInDay = 24 * 60;
  const times: string[] = [];

  if (value) {
    times.push(value);
  }
  range(0, totalMinutesInDay, step).forEach(minute => {
    const hours = Math.floor(minute / 60);
    const minutes = minute % 60;
    const formattedTime = formatTimeText(hours, minutes);
    times.push(formattedTime);
  });

  const sanitized = uniq(times);

  return sanitized.map(item => {
    return {
      text: item,
      value: item,
      title: item
    };
  });
};

const isValidTimeText = (text?: string): boolean => {
  return stringToTimePickerHourOrUndefined(text) !== undefined;
};

const hasAllValidCharacters = (text?: string): boolean => {
  if (!text) return true;
  const regex = /^[0-9:]+$/;
  const match = text.match(regex) ?? false;
  return match && (countBy(text)[":"] || 0) <= 1;
};

const addSeparator = (text: string): string => {
  if (text.length === 2 && !text.includes(":")) {
    return `${text}:`;
  }
  return text;
};

const formatTextValue = (
  previousText?: string,
  text?: string
): Optional<string> => {
  if (startsWith(text, ":")) return "00:";
  if (isValidTimeText(text) || !text) return text;

  // This checks if the ':' is being erased
  if (
    previousText?.endsWith(":") &&
    previousText.startsWith(text) &&
    (countBy(text)[":"] || 0) <= 1
  )
    return text.slice(0, -1);

  const numberArray = text.split(":");
  const hour = numberArray[0] ? Number(numberArray[0]) : undefined;
  const minutesText = numberArray[1];
  const minutes = minutesText ? Number(minutesText) : undefined;

  if (hour === undefined) return text;

  if (minutes === undefined) {
    if (hour > 23) return previousText;
    if (hour >= 3) return addSeparator(text.padStart(2, "0"));

    return addSeparator(text);
  }

  if (minutesText?.length === 1 && minutes > 5) {
    return formatTimeText(hour, minutes);
  }
  if (minutes > 59) return previousText;
  return formatTimeText(hour, minutes, false);
};

export const TimePicker: FC<TimePickerProps> = ({
  className,
  value,
  onChange,
  EndIcon = AccessTime,
  placeholder,
  stepMinutes = 15,
  closeText,
  clearText,
  loadingText,
  noOptionsText,
  openText,
  onBlur,
  fullWidth,
  errorText,
  showError,
  endIconShouldNotRotate = true,
  clearable,
  disabled,
  showHelperOrErrorText = true
}) => {
  const classes = useTimePickerStyles({ fullWidth });
  const externalTextValue = value ? timePickerHourToString(value) : undefined;
  const [textValue, setTextValue] = useState(externalTextValue ?? "");
  const [isOpen, setIsOpen] = useState(false);

  const timeOptions = createTimeOptions(stepMinutes, externalTextValue);

  const onChangeText = useCallback(
    (text?: string) => {
      setTextValue(text ?? "");
      const timeValue = stringToTimePickerHourOrUndefined(text);
      if (timeValue) {
        onChange?.(timeValue);
        setIsOpen(false);
      }
    },
    [setTextValue, onChange]
  );

  const onInputChange = useCallback(
    (text?: string) => {
      if (!hasAllValidCharacters(text)) return;
      const formattedText = formatTextValue(textValue, text);
      setTextValue(formattedText ?? "");
      const timeValue = stringToTimePickerHourOrUndefined(formattedText);
      if (timeValue || isEmpty(text)) {
        setIsOpen(false);
        onChange?.(timeValue);
      } else {
        setIsOpen(true);
      }
    },
    [textValue, setTextValue, onChange, setIsOpen]
  );

  const onInputBlur = useCallback(() => {
    onBlur?.();
    setIsOpen(false);
    if (textValue.length < 5) {
      setTextValue("");
      onChange?.(undefined);
    }
  }, [onBlur, textValue, setTextValue, onChange]);

  const onInputClicked = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <Autocomplete
      className={getClassName(className, classes.root)}
      inputValue={textValue}
      value={externalTextValue}
      onChange={onChangeText}
      options={timeOptions}
      closeText={closeText ?? ""}
      clearText={clearText ?? ""}
      loadingText={loadingText ?? ""}
      noOptionsText={noOptionsText ?? ""}
      openText={openText ?? ""}
      label={placeholder}
      freeSolo
      EndIcon={EndIcon}
      onInputChange={onInputChange}
      onBlur={onInputBlur}
      fullWidth={fullWidth}
      errorText={errorText}
      showError={showError}
      endIconShouldNotRotate={endIconShouldNotRotate}
      clearable={clearable}
      openOptionList={isOpen}
      onClick={!disabled ? onInputClicked : undefined}
      disabled={disabled}
      maxOptionsLimit={100}
      showHelperOrErrorText={showHelperOrErrorText}
    />
  );
};
