import { isEmpty, isUndefined } from "lodash-es";
import {
  getModelOrUndefined,
  referenceIsModel
} from "model/primitives/modelReference/utils";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { PatientHospitalizationStatus } from "api/person/patientContainerWithExtraData";
import {
  EncounterService,
  EncounterStatus
} from "model/resource/entities/encounter/encounter";
import {
  AllergyIntolerance,
  AllergyStatus,
  KnownAllergyIntoleranceElements
} from "model/resource/medical/allergyIntolerance/allergyIntolerance";
import {
  applyFormatToDate,
  DateFormat,
  dateInputToDateTime,
  dateIsAfter,
  DateTime,
  getCurrentDateTime,
  notUndefined,
  Optional,
  optionalDateInputToDateTime,
  DurationUnitValues
} from "@laba/ts-common";
import { ModelId } from "model/primitives/model/model";
import { ResourceType } from "model/primitives/resourceModel";
import { PatientMeasure } from "model/resource/medical/observation/patientMeasure";
import { getFirstCreatinineClearanceMeasures } from "model/resource/medical";
import { Diagnosis } from "model/resource/medical/condition/diagnosis";
import { ContactPointRole, Email, Phone } from "model/primitives/contactPoint";
import {
  getLegalIdentifierByImportance,
  getMedicalIdentifierByImportance,
  Identifier,
  KnownIdentifierSystem
} from "model/primitives";
import { User } from "model/resource/person/user";
import { KnownQuantityUnit, Quantity } from "model/primitives/quantity";
import {
  getFirstGeneralPractitioner,
  getFirstPhysiatristPractitioner,
  isEncounterInProgress
} from "model/resource/entities/encounter/helpers";
import { Organization } from "model/resource/entities/organization/organization";
import { Code } from "model/resource/entities/codeSystem";
import {
  getConditionDisplayName,
  getFirstMedicalAdmissionDiagnosisDiagnosis,
  getFirstSantaCatalinaClassificationDiagnosis,
  getHospitalizedDays,
  KnownMeasureObservationCode,
  MeasureObservationCode
} from "model/resource";
import { BasePerson } from "./person";
import {
  PatientContainerWithExtraData,
  PatientPrescriptionStatus
} from "./patient/patientContainerWithExtraData";
import { Patient } from "./patient/patient";
import {
  Practitioner,
  PractitionerQualification,
  QualificationValidationStatus
} from "./practitioner";

export const allergiesListIndicateNoKnownAllergies = (
  allergies: ModelReference<AllergyIntolerance>[]
): boolean => {
  const activeAllergies = allergies
    .filter(referenceIsModel)
    .filter(allergy => allergy.status === AllergyStatus.Active);
  return (
    activeAllergies.length === 1 &&
    activeAllergies[0]?.element ===
      KnownAllergyIntoleranceElements.NoKnownAllergy
  );
};

export const getAgeFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<AgePersonFlatData> => {
  if (!person?.personalData.birthDate) return undefined;

  const today = getCurrentDateTime();
  const birthDate = DateTime.fromApiDate(person.personalData.birthDate);
  const {
    years = 0,
    months = 0,
    days = 0
  } = today
    .diff(birthDate, [
      DurationUnitValues.Years,
      DurationUnitValues.Months,
      DurationUnitValues.Days
    ])
    .toObject();

  return {
    years: Math.max(0, Math.floor(years)),
    months: Math.max(0, Math.floor(months)),
    days: Math.max(0, Math.ceil(days))
  };
};

export const getFullNameFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): string => {
  const personalData = person?.personalData;
  const firstName = personalData?.firstName;
  const otherNames = personalData?.otherName;
  const lastName = personalData?.lastName;
  return [firstName, otherNames, lastName].filter(Boolean).join(" ");
};

export const getReversedFullNameFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): string => {
  const personalData = person?.personalData;
  const firstName = personalData?.firstName;
  const otherNames = personalData?.otherName;
  const lastName = personalData?.lastName;
  const name = [firstName, otherNames].filter(notUndefined).join(" ");
  return [lastName, !isEmpty(name) ? name : undefined]
    .filter(notUndefined)
    .join(", ");
};

export const getNotificationEmailObjectFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<Email> => {
  return person?.contactData?.emailList?.find(
    contactEmail => contactEmail.role === ContactPointRole.NotificationEmail
  );
};

export const getNotificationPhoneObjectFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<Phone> => {
  return person?.contactData?.phoneList?.find(
    contactPhone => contactPhone.role === ContactPointRole.NotificationPhone
  );
};

export const getNotificationEmailFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> => {
  return getNotificationEmailObjectFromPerson(person)?.value;
};

export const getNotificationPhoneFromPerson = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> => {
  return getNotificationPhoneObjectFromPerson(person)?.value;
};

export const getBirthDate = <T extends ResourceType>(
  person?: BasePerson<T>,
  dateFormat: DateFormat = DateFormat.Spanish
): Optional<string> => {
  const birthDate = person?.personalData.birthDate;
  return applyFormatToDate(birthDate, dateFormat);
};

export const getIdentifierBySystemFromPatient = <T extends ResourceType>(
  system: KnownIdentifierSystem,
  person?: BasePerson<T>
): Optional<string> => {
  const identifiers = person?.personalData.identifierList;
  return identifiers?.find(identifier => identifier.system === system)?.value;
};

export const getDNINumberFromPatient = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> =>
  getIdentifierBySystemFromPatient(KnownIdentifierSystem.Dni, person);

export const getPassportNumberFromPatient = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> =>
  getIdentifierBySystemFromPatient(KnownIdentifierSystem.Passport, person);

export const getCINumberFromPatient = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> =>
  getIdentifierBySystemFromPatient(KnownIdentifierSystem.Ci, person);

export const getRUTNumberFromPatient = <T extends ResourceType>(
  person?: BasePerson<T>
): Optional<string> =>
  getIdentifierBySystemFromPatient(KnownIdentifierSystem.Rut, person);

export const getHealthcarePlanFromPatient = (
  patient?: Patient
): Optional<string> => {
  return patient?.patientData?.healthcarePayer?.plan;
};

export const getCredentialNumberFromPatient = (
  patient?: Patient
): Optional<string> => {
  return patient?.patientData?.healthcarePayer?.credentialNumber;
};

export const getPatientHealthCareProvider = (
  patient?: Patient
): Optional<Organization> => {
  const healthcareProviderOrganization =
    patient?.patientData?.healthcarePayer?.provider;
  return getModelOrUndefined(healthcareProviderOrganization);
};
export const getHealthcareProviderName = (
  patient?: Patient
): Optional<string> => {
  return getPatientHealthCareProvider(patient)?.name;
};

export const getHealthcareProviderPlan = (
  patient?: Patient
): Optional<string> => {
  return patient?.patientData?.healthcarePayer?.plan;
};

export const getMeasurementByTypeFromPatientMeasurementList = (
  measurementType: MeasureObservationCode,
  measurementList?: MeasurementFlatData[]
): Optional<MeasurementFlatData> => {
  const measurementRequired = measurementList?.find(
    measurement => measurement.type === measurementType
  );
  return measurementRequired;
};

export const getWeightMeasurementFromPatientData = (
  measurementList?: MeasurementFlatData[]
): Optional<MeasurementFlatData> =>
  getMeasurementByTypeFromPatientMeasurementList(
    KnownMeasureObservationCode.Weight,
    measurementList
  );

export enum PatientAllergyStatusFlatData {
  TestedWithAllergies = "TestedWithAllergies",
  NoAllergies = "NoAllergies",
  Untested = "Untested"
}

export const getPatientContainerAllergyStatus = (
  allergyIntoleranceList?: AllergyIntolerance[]
): PatientAllergyStatusFlatData => {
  return isEmpty(allergyIntoleranceList)
    ? PatientAllergyStatusFlatData.Untested
    : allergyIntoleranceList?.some(
        allergy =>
          allergy.element === KnownAllergyIntoleranceElements.NoKnownAllergy
      )
    ? PatientAllergyStatusFlatData.NoAllergies
    : PatientAllergyStatusFlatData.TestedWithAllergies;
};
export interface TagFlatData {
  code?: string;
  display?: string;
  abbreviation?: string;
  color?: string;
}
export interface MeasurementFlatData {
  type?: MeasureObservationCode;
  quantity?: number;
  unit?: string;
  measurementDate?: DateTime;
}

export interface AllergyIntoleranceFlatData {
  elementName?: string;
  element?: string;
}

export interface AgePersonFlatData {
  years?: number;
  months?: number;
  days?: number;
}

export interface PatientContainerFlatData {
  profilePictureUrl?: string;
  fullName: string;
  name: string;
  lastName: string;
  medicalIdentifier?: Identifier;
  birthDate?: DateTime;
  age?: AgePersonFlatData;
  healthcareProviderName?: string;
  healthcareProviderId?: ModelId;
  credentialNumber?: string;
  healthcarePayerName?: string;
  healthcarePayerPlan?: string;
  measurementList?: MeasurementFlatData[];
  allergyIntolerancesList?: AllergyIntoleranceFlatData[];
  allergyStatus: PatientAllergyStatusFlatData;
  tagList?: TagFlatData[];
  hospitalizationStatus: PatientHospitalizationStatus;
  bedName?: string;
  bedFullName?: string;
  bedId?: ModelId;
  startDate?: DateTime;
  daysHospitalized?: number;
  finishDate?: DateTime;
  diagnosisName?: string;
  service?: EncounterService;
  classification?: string;
  lastRegisterDate?: DateTime;
  prescriptionStatus?: PatientPrescriptionStatus;
  email?: string;
  phoneNumber?: string;
  legalIdentifier?: Identifier;
}

export const getPatientContainerWithExtraDataHelper = (
  patientContainer?: PatientContainerWithExtraData,
  patientTagGetter?: (tag?: Code) => Optional<TagFlatData>
): PatientContainerFlatData => {
  // Patient data
  const currentPatient = patientContainer?.patient;
  const profilePictureUrl = currentPatient?.personalData.profilePicture?.url;
  const firstName = currentPatient?.personalData.firstName;
  const otherName = currentPatient?.personalData.otherName;
  const lastName = currentPatient?.personalData.lastName ?? "";
  const name = [firstName, otherName]
    .filter(value => !isEmpty(value))
    .join(" ");
  const fullName = getFullNameFromPerson(currentPatient);
  const medicalIdentifier = getMedicalIdentifierByImportance(
    currentPatient?.personalData.identifierList
  );
  const legalIdentifier = getLegalIdentifierByImportance(
    currentPatient?.personalData.identifierList
  );

  const birthDate = optionalDateInputToDateTime(
    currentPatient?.personalData.birthDate
  );
  const age = getAgeFromPerson(currentPatient);
  const healthcareProviderOrganization =
    currentPatient?.patientData?.healthcarePayer?.provider;
  const healthcareProviderName = getHealthcareProviderName(currentPatient);
  const healthcareProviderId = getModelOrUndefined(
    healthcareProviderOrganization
  )?.id;
  const credentialNumber = getCredentialNumberFromPatient(currentPatient);
  const healthcarePayerName = getModelOrUndefined(
    currentPatient?.patientData?.healthcarePayer?.provider
  )?.name;
  const healthcarePayerPlan = getHealthcarePlanFromPatient(currentPatient);

  // Measurements data
  const measurementList = patientContainer?.measurementList?.map(
    measurement => {
      return {
        type: measurement.type,
        unit: measurement.value?.unit,
        quantity: measurement.value?.quantity,
        measurementDate: optionalDateInputToDateTime(
          measurement.effectiveDateTime
        )
      };
    }
  );

  // Allergy intolerance list
  const allergyIntolerancesList = patientContainer?.allergyIntoleranceList?.map(
    allergy => {
      return {
        elementName: allergy.elementName,
        element: allergy.element
      };
    }
  );
  const allergyStatus = getPatientContainerAllergyStatus(
    patientContainer?.allergyIntoleranceList
  );

  // Tags
  const tagList = patientContainer?.tagList
    ?.map(patientTag => {
      return patientTagGetter?.(patientTag.value);
    })
    ?.filter(notUndefined);

  // Hospitalization
  const currentHospitalization = patientContainer?.hospitalization;
  const hospitalizationStatus = !currentHospitalization
    ? PatientHospitalizationStatus.Ambulatory
    : currentHospitalization.status === EncounterStatus.InProgress
    ? PatientHospitalizationStatus.Hospitalized
    : currentHospitalization.status === EncounterStatus.Finished
    ? PatientHospitalizationStatus.Discharged
    : PatientHospitalizationStatus.Ambulatory;
  const bed = getModelOrUndefined(currentHospitalization?.currentBed?.bed);
  const bedName = bed?.name;
  const bedFullName = bed?.description;
  const bedId = bed?.id;
  const startDate = optionalDateInputToDateTime(
    currentHospitalization?.startDate
  );
  const finishDate = optionalDateInputToDateTime(
    currentHospitalization?.finishDate
  );
  const daysHospitalized = getHospitalizedDays(currentHospitalization);
  // TODO: Modify in HIS-8102
  const diagnosisRef = currentHospitalization?.diagnosis;
  const diagnosisName = getConditionDisplayName(
    getModelOrUndefined(
      getFirstMedicalAdmissionDiagnosisDiagnosis(diagnosisRef)
    )
  );
  const service = currentHospitalization?.service;
  const classification = getModelOrUndefined(
    getFirstSantaCatalinaClassificationDiagnosis(diagnosisRef)
  )?.code?.code;

  // Last Register Date
  const lastEvolutionEditDate = patientContainer?.lastEvolutionEditDate;
  const lastPrescriptionEditDate = patientContainer?.lastPrescriptionEditDate;
  const lastRegisterDate =
    lastEvolutionEditDate && lastPrescriptionEditDate
      ? dateIsAfter(
          dateInputToDateTime(lastEvolutionEditDate),
          dateInputToDateTime(lastPrescriptionEditDate)
        )
        ? dateInputToDateTime(lastEvolutionEditDate)
        : dateInputToDateTime(lastPrescriptionEditDate)
      : lastEvolutionEditDate && !lastPrescriptionEditDate
      ? dateInputToDateTime(lastEvolutionEditDate)
      : !lastEvolutionEditDate && lastPrescriptionEditDate
      ? dateInputToDateTime(lastPrescriptionEditDate)
      : undefined;

  // Prescription Status
  const prescriptionStatus = patientContainer?.prescriptionStatus;

  const phoneNumber = getNotificationPhoneFromPerson(currentPatient);
  const email = getNotificationEmailFromPerson(currentPatient);

  return {
    profilePictureUrl,
    name,
    lastName,
    fullName,
    medicalIdentifier,
    birthDate,
    age,
    healthcareProviderName,
    healthcareProviderId,
    credentialNumber,
    healthcarePayerName,
    healthcarePayerPlan,
    measurementList,
    allergyIntolerancesList,
    allergyStatus,
    tagList,
    hospitalizationStatus,
    bedName,
    bedFullName,
    bedId,
    startDate,
    daysHospitalized,
    finishDate,
    diagnosisName,
    service,
    classification,
    lastRegisterDate,
    prescriptionStatus,
    email,
    phoneNumber,
    legalIdentifier
  };
};

/* 
patientContainer.hospitalization can be undefined whether the patient is 'ambulatory'
or the request was not made with the 'withHospitalization' parameter . So this function needs
to be called only if you made the request with 'withHospitalization' parameter
*/
export const isPatientHospitalized = (
  patientContainer?: PatientContainerWithExtraData
): boolean => {
  const currentHospitalization = patientContainer?.hospitalization;
  if (!currentHospitalization) {
    return false;
  }
  return isEncounterInProgress(currentHospitalization);
};

export const isPatientDischarged = (
  patientContainer?: PatientContainerWithExtraData
): boolean => {
  const currentHospitalization = patientContainer?.hospitalization;
  if (!currentHospitalization) {
    return false;
  }
  if (currentHospitalization.status === EncounterStatus.Finished) {
    return true;
  }
  return false;
};

export const isPatientAmbulatory = (
  patientContainer?: PatientContainerWithExtraData
): boolean => {
  return !isPatientHospitalized(patientContainer);
};

export const getFirstCreatinineMeasurementFromPatientContainer = (
  patientContainer: PatientContainerWithExtraData
): Optional<PatientMeasure> => {
  return getFirstCreatinineClearanceMeasures(patientContainer.measurementList);
};

export const getFirstClassificationFromPatientContainer = (
  patientContainer: PatientContainerWithExtraData
): Optional<Diagnosis> => {
  return getFirstSantaCatalinaClassificationDiagnosis(
    patientContainer.hospitalization?.diagnosis
  );
};

export const getFirstGeneralPractitionerFromPatientContainer = (
  patientContainer: PatientContainerWithExtraData
): Optional<ModelReference<Practitioner>> => {
  return getFirstGeneralPractitioner(patientContainer.hospitalization);
};

export const getFirstPsychiatristPractitionerFromPatientContainer = (
  patientContainer: PatientContainerWithExtraData
): Optional<ModelReference<Practitioner>> => {
  return getFirstPhysiatristPractitioner(patientContainer.hospitalization);
};

export const getFullNameFromUser = (user?: User): string =>
  [user?.name, user?.surname].filter(Boolean).join(" ");

export const getEmailFromUser = (user?: User): Optional<string> => user?.email;

export const getUsernameFromUser = (user?: User): Optional<string> =>
  user?.username;

export const getKnownMeasureObservation = (
  code: KnownMeasureObservationCode,
  measurements?: MeasurementFlatData[]
): Optional<MeasurementFlatData> =>
  measurements?.find(measurement => measurement.type === code);

export const getBMIQuantityAndUnit = (
  bmiMeasurement?: MeasurementFlatData,
  weightMeasurement?: MeasurementFlatData,
  heightMeasurement?: MeasurementFlatData
): Optional<Quantity> => {
  const weightQty = weightMeasurement?.quantity;
  const weightUnit = weightMeasurement?.unit;
  const heightQty = heightMeasurement?.quantity;
  const heightUnit = heightMeasurement?.unit;
  const bmiQuantity = bmiMeasurement?.quantity;
  if (
    !isUndefined(weightQty) &&
    !isUndefined(heightQty) &&
    weightUnit === KnownQuantityUnit.Kilogram &&
    heightUnit === KnownQuantityUnit.Meter
  ) {
    const bmiQty = Number((weightQty / heightQty ** 2).toFixed(2));
    return {
      quantity: bmiQty,
      unit: `${weightUnit}/${heightUnit}2`
    };
  }
  if (!isUndefined(bmiQuantity)) {
    return { quantity: bmiMeasurement?.quantity, unit: bmiMeasurement?.unit };
  }
  return undefined;
};

const filterPractitionerRegistrationQualificationListByStatus = (
  practitioner?: Practitioner,
  status?: QualificationValidationStatus
): PractitionerQualification[] => {
  return (
    practitioner?.healthcareProfessionalData?.registrationQualification?.filter(
      q => q.validationStatus === status
    ) ?? []
  );
};
const getPractitionerValidatedRegistrationQualificationList = (
  practitioner?: Practitioner
): PractitionerQualification[] => {
  return filterPractitionerRegistrationQualificationListByStatus(
    practitioner,
    QualificationValidationStatus.Validated
  );
};
const getPractitionerPendingRegistrationQualificationList = (
  practitioner?: Practitioner
): PractitionerQualification[] => {
  return filterPractitionerRegistrationQualificationListByStatus(
    practitioner,
    QualificationValidationStatus.Pending
  );
};

export const hasPractitionerValidatedRegistrationQualification = (
  practitioner?: Practitioner
): boolean => {
  return !isEmpty(
    getPractitionerValidatedRegistrationQualificationList(practitioner)
  );
};

export const hasPractitionerPendingRegistrationQualification = (
  practitioner?: Practitioner
): boolean => {
  return !isEmpty(
    getPractitionerPendingRegistrationQualificationList(practitioner)
  );
};

export const getPractitionerRegistrationQualificationValue = (
  practitionerQualification?: PractitionerQualification
): Optional<string> => {
  return practitionerQualification?.identifier?.value;
};
