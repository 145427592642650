import { FCC, IconC } from "@laba/react-common";
import React from "react";
import { StyleVariant } from "model/themeVariant";
import { IconButtonType } from "components/buttons/IconButton/IconButton";
import { CloseIcon, SaveIcon } from "components/icons";
import { ColorTagItem } from "components/tags/ColorTag/ColorTag";
import { Noop } from "@laba/ts-common";
import { CardVariant } from "../cardVariants";
import { GenericCardHeader } from "./GenericCardHeader/GenericCardHeader";
import { Card } from "../Card/Card";

export interface CardWithGenericHeaderProps {
  className?: string;
  childrenContainerClassName?: string;
  primaryStyle?: StyleVariant;
  secondaryStyle?: StyleVariant;
  headerStyle?: StyleVariant;
  cardVariant?: CardVariant;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: Noop;
  onSecondaryButtonClick?: Noop;
  PrimaryButtonIcon?: IconC;
  SecondaryButtonIcon?: IconC;
  fullWidth?: boolean;
  formId?: string;
  loading?: boolean;
  disablePrimaryButton?: boolean;
  disableSecondaryButton?: boolean;
  headerTitle?: string;
  headerColor?: string;
  LeftIconHeader?: IconC;
  RightIconHeader?: IconC;
  onRightIconClick?: Noop;
  ExtraRightIconHeader?: IconC;
  onExtraRightIconClick?: Noop;
  extraRightIconHeaderButtonType?: IconButtonType;
  disableRightIcon?: boolean;
  disableExtraRightIcon?: boolean;
  hideUpperPrimaryButtons?: boolean;
  isMobile?: boolean;
  withStickyHeader?: boolean;
  top?: number;
  headerTags?: ColorTagItem[];
  hideHeader?: boolean;
}

export const CardWithGenericHeader: FCC<CardWithGenericHeaderProps> = ({
  children,
  childrenContainerClassName,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  PrimaryButtonIcon,
  SecondaryButtonIcon,
  className,
  formId,
  isMobile,
  primaryStyle = StyleVariant.Primary,
  secondaryStyle = StyleVariant.Primary,
  headerStyle = StyleVariant.BlackWhite,
  cardVariant = CardVariant.Horizontal,
  fullWidth = false,
  disablePrimaryButton = false,
  disableSecondaryButton = false,
  loading = false,
  headerTitle,
  headerColor,
  LeftIconHeader,
  RightIconHeader = CloseIcon,
  onRightIconClick = onSecondaryButtonClick,
  ExtraRightIconHeader = SaveIcon,
  onExtraRightIconClick = onPrimaryButtonClick,
  disableRightIcon = false,
  disableExtraRightIcon = false,
  withStickyHeader = false,
  top = 0,
  hideUpperPrimaryButtons = false,
  headerTags,
  hideHeader
}) => {
  return (
    <Card
      CardHeader={
        <GenericCardHeader
          headerTitle={headerTitle}
          headerColor={headerColor}
          LeftIconHeader={LeftIconHeader}
          RightIconHeader={RightIconHeader}
          onRightIconClick={onRightIconClick}
          ExtraRightIconHeader={ExtraRightIconHeader}
          onExtraRightIconClick={onExtraRightIconClick}
          cardVariant={cardVariant}
          primaryStyle={headerStyle}
          disableRightIcon={disableRightIcon}
          disableExtraRightIcon={disableExtraRightIcon}
          loading={loading}
          formId={formId}
          isMobile={isMobile}
          hideUpperPrimaryButtons={hideUpperPrimaryButtons}
          onPrimaryButtonClick={onPrimaryButtonClick}
          primaryButtonText={primaryButtonText}
          disablePrimaryButton={disablePrimaryButton}
          PrimaryButtonIcon={PrimaryButtonIcon}
          SecondaryButtonIcon={SecondaryButtonIcon}
          disableSecondaryButton={disableSecondaryButton}
          secondaryButtonText={secondaryButtonText}
          onSecondaryButtonClick={onSecondaryButtonClick}
          tags={headerTags}
          hide={hideHeader}
        />
      }
      loading={loading}
      cardVariant={cardVariant}
      primaryStyle={primaryStyle}
      childrenContainerClassName={childrenContainerClassName}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      PrimaryButtonIcon={PrimaryButtonIcon}
      SecondaryButtonIcon={SecondaryButtonIcon}
      className={className}
      formId={formId}
      secondaryStyle={secondaryStyle}
      fullWidth={fullWidth}
      disablePrimaryButton={disablePrimaryButton}
      disableSecondaryButton={disableSecondaryButton}
      isMobile={isMobile}
      withStickyHeader={withStickyHeader}
      top={top}
      showBottomButtons={hideUpperPrimaryButtons || isMobile}
    >
      {children}
    </Card>
  );
};
