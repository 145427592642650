import { AppointmentNotificationConfigCodes } from "model/resource/appointment/appointment";
import { NotificationActionCode } from "model/communication";

export const mapAppointmentNotificationConfigCodeToNotificationActionCode = (
  code: AppointmentNotificationConfigCodes,
  isPatient: boolean,
  isSeries: boolean
): NotificationActionCode => {
  switch (code) {
    case AppointmentNotificationConfigCodes.Cancel:
      return isPatient
        ? isSeries
          ? NotificationActionCode.AppointmentCancelSeriesForPatient
          : NotificationActionCode.AppointmentCancelForPatient
        : isSeries
        ? NotificationActionCode.AppointmentCancelSeriesForPractitioner
        : NotificationActionCode.AppointmentCancelForPractitioner;

    case AppointmentNotificationConfigCodes.Create:
      return isPatient
        ? isSeries
          ? NotificationActionCode.AppointmentCreateSeriesForPatient
          : NotificationActionCode.AppointmentCreateForPatient
        : isSeries
        ? NotificationActionCode.AppointmentCreateSeriesForPractitioner
        : NotificationActionCode.AppointmentCreateForPractitioner;

    case AppointmentNotificationConfigCodes.Confirm:
      return isPatient
        ? NotificationActionCode.AppointmentConfirmForPatient
        : NotificationActionCode.AppointmentConfirmForPractitioner;

    case AppointmentNotificationConfigCodes.Admit:
      return isPatient
        ? NotificationActionCode.AppointmentAdmitForPatient
        : NotificationActionCode.AppointmentAdmitForPractitioner;
    case AppointmentNotificationConfigCodes.Attend:
      return isPatient
        ? NotificationActionCode.AppointmentAttendForPatient
        : NotificationActionCode.AppointmentAttendForPractitioner;
    case AppointmentNotificationConfigCodes.Edit:
      return isPatient
        ? isSeries
          ? NotificationActionCode.AppointmentEditSeriesForPatient
          : NotificationActionCode.AppointmentEditForPatient
        : isSeries
        ? NotificationActionCode.AppointmentEditSeriesForPractitioner
        : NotificationActionCode.AppointmentEditForPractitioner;
    case AppointmentNotificationConfigCodes.EditPatientData:
      return isSeries
        ? NotificationActionCode.AppointmentEditSeriesPatientDataForPatient
        : NotificationActionCode.AppointmentEditPatientDataForPatient;
    default:
      return isPatient
        ? NotificationActionCode.AppointmentFinishForPatient
        : NotificationActionCode.AppointmentFinishForPractitioner;
  }
};
