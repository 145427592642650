import { FC, I18nT, useField, useI18n } from "@laba/react-common";
import {
  AppointmentStatus,
  RegenerateAppointmentNotificationsParamsKey
} from "@laba/nexup-api";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { tkCP } from "translation/i18n";
import {
  DateInput,
  SearchableListSimpleInput,
  TextInput
} from "@laba/nexup-components";
import {
  ApiDate,
  dateTimeFromApiDateOrUndefined,
  dateTimeToApiDateOrUndefined,
  ModelId
} from "@laba/ts-common";

const tk = tkCP.adminPage.dashboard.regenerateAppointmentNotifications.fields;

const appointmentStatusOptionList = Object.values(AppointmentStatus);

const getDisplayTextFromStatus = (
  status: AppointmentStatus,
  t: I18nT
): string => t(tk.statusField.statusOptions[status]);

export const RegenerateAppointmentNotificationsFormContent: FC = () => {
  const { t } = useI18n();

  const {
    input: {
      value: scheduleIdValue,
      onChange: onChangeScheduleId,
      onBlur: scheduleIdOnBlur
    },
    meta: { error: scheduleIdError }
  } = useField<ModelId, string>(RegenerateAppointmentNotificationsParamsKey.id);

  const {
    input: {
      value: appointmentStatusListValue,
      onChange: appointmentStatusListOnChange,
      onBlur: appointmentStatusListOnBlur
    },
    meta: {
      error: appointmentStatusListError,
      touched: appointmentStatusListTouched
    }
  } = useField<AppointmentStatus[], string>(
    RegenerateAppointmentNotificationsParamsKey.params.status
  );

  const {
    input: {
      value: startDate,
      onChange: onChangeStartDate,
      onBlur: startDateOnBlur
    },
    meta: { error: startDateError, touched: startDateTouched }
  } = useField<ApiDate, string>(
    RegenerateAppointmentNotificationsParamsKey.params.startDate
  );

  const {
    input: { value: endDate, onChange: onChangeEndDate, onBlur: endDateOnBlur },
    meta: { error: endDateError, touched: endDateTouched }
  } = useField<ApiDate, string>(
    RegenerateAppointmentNotificationsParamsKey.params.endDate
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item sm={6}>
        <Typography title={t(tk.scheduleIdField.title)}>
          {t(tk.scheduleIdField.title)}
        </Typography>

        <TextInput
          value={scheduleIdValue}
          onChange={onChangeScheduleId}
          label={t(tk.scheduleIdField.placeholder)}
          fullWidth
          onBlur={scheduleIdOnBlur}
          errorText={scheduleIdError}
        />
      </Grid>

      <Grid item sm={6}>
        <Typography title={t(tk.statusField.title)}>
          {t(tk.statusField.title)}
        </Typography>

        <SearchableListSimpleInput<AppointmentStatus>
          getOptionFromValue={v => ({
            value: v,
            text: getDisplayTextFromStatus(v, t),
            title: getDisplayTextFromStatus(v, t)
          })}
          valueList={appointmentStatusListValue}
          optionList={appointmentStatusOptionList}
          setOptionList={appointmentStatusListOnChange}
          onBlur={appointmentStatusListOnBlur}
          errorText={appointmentStatusListError}
          showError={appointmentStatusListTouched ?? true}
          noOptionsText={t(tk.statusField.noOptionsText)}
          fullWidth
        />
      </Grid>

      <Grid item sm={6}>
        <Typography title={t(tk.startDateField.title)}>
          {t(tk.startDateField.title)}
        </Typography>
        <DateInput
          value={dateTimeFromApiDateOrUndefined(startDate)}
          onChange={d => onChangeStartDate(dateTimeToApiDateOrUndefined(d))}
          onBlur={startDateOnBlur}
          errorText={startDateError}
          showError={startDateTouched ?? true}
          placeholder={t(tk.startDateField.placeholder)}
          fullWidth
          clearable
        />
      </Grid>

      <Grid item sm={6}>
        <Typography title={t(tk.endDateField.title)}>
          {t(tk.endDateField.title)}
        </Typography>
        <DateInput
          value={dateTimeFromApiDateOrUndefined(endDate)}
          onChange={d => onChangeEndDate(dateTimeToApiDateOrUndefined(d))}
          onBlur={endDateOnBlur}
          errorText={endDateError}
          showError={endDateTouched ?? true}
          placeholder={t(tk.endDateField.placeholder)}
          fullWidth
          clearable
        />
      </Grid>
    </Grid>
  );
};
