import { WebsocketClientContext } from "providers";
import { Children, ReactElement } from "components/types";
import React, { useMemo, useState } from "react";
import {
  WebSocketClient,
  WebsocketClientDisconnectIFrame,
  webSocketClientGetter,
  WebSocketClientGetterProps
} from "@laba/ts-common";

export interface WebsocketClientProviderProps {
  context: WebsocketClientContext;
  children: Children;
  props: WebSocketClientGetterProps;
}

export const WebsocketClientProvider = ({
  children,
  context,
  props
}: WebsocketClientProviderProps): ReactElement => {
  const [isConnected, setIsConnected] = useState(false);
  const { onConnectionReady, onDisconnect } = props;
  const wrappedOnConnectionReady = () => {
    onConnectionReady?.();
    setIsConnected(true);
  };
  const wrappedOnDisconnect = (frame: WebsocketClientDisconnectIFrame) => {
    onDisconnect?.(frame);
    setIsConnected(false);
  };
  const [websocketClient] = useState<WebSocketClient>(
    webSocketClientGetter({
      ...props,
      onConnectionReady: wrappedOnConnectionReady,
      onDisconnect: wrappedOnDisconnect
    })()
  );

  const contextValue = useMemo(() => {
    return { isConnected, client: websocketClient };
  }, [isConnected, websocketClient]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
