import { FC, OnBlurEvent, TextVariant, useTheme } from "@laba/react-common";
import { StyleVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import React, { useState } from "react";

import { Theme } from "model/theme";
import { ErrorText } from "components/text/ErrorText/ErrorText";
import {
  useSwitchToggleStyle,
  useSwitchToggleStyleOverrides
} from "./SwitchToggleStyle";

export interface SwitchToggleProps {
  className?: string;
  title?: string;
  text?: string;
  textVariant?: TextVariant;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: OnBlurEvent<HTMLButtonElement>;
  disabled?: boolean;
  style?: StyleVariant;
  showErrorIfTouchOnly?: boolean;
  showErrorSpaceWhenErrorEmpty?: boolean;
  errorText?: string;
  showTextLeft?: boolean;
}

export const SwitchToggle: FC<SwitchToggleProps> = ({
  className,
  onChange,
  onBlur,
  title,
  text,
  errorText,
  showErrorIfTouchOnly = false,
  checked = false,
  disabled = false,
  style = StyleVariant.Primary,
  showTextLeft = false,
  textVariant = TextVariant.Caption
}) => {
  const [touched, setTouched] = useState(false);
  const hasErrorText = Boolean(errorText);
  const isAllowedToShowError = !showErrorIfTouchOnly || touched;
  const showError = hasErrorText && isAllowedToShowError;
  const classes = useSwitchToggleStyle({ textVariant });
  const theme = useTheme<Theme>();
  const overrides = useSwitchToggleStyleOverrides(theme);
  const muiTheme = useMuiTheme(style, overrides);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={className}>
        <div className={classes.container}>
          {showTextLeft && text && <span className={classes.text}>{text}</span>}
          <Switch
            disabled={disabled}
            title={title}
            checked={checked}
            onChange={e => {
              onChange?.(e.target.checked);
            }}
            onBlur={e => {
              setTouched(true);
              onBlur?.(e);
            }}
            color="primary"
            size="medium"
          />
          {!showTextLeft && text && (
            <span className={classes.text}>{text}</span>
          )}
        </div>
        <ErrorText showError={showError} error={errorText} />
      </div>
    </ThemeProvider>
  );
};
