import React, { useCallback, useState } from "react";
import { FC, Form, useI18n } from "@laba/react-common";
import { DefaultButton, Popup } from "@laba/nexup-components";
import { useAppDispatch } from "store/store";
import { tkCP } from "translation/i18n";
import { RegenerateAppointmentNotificationsRequestBody } from "@laba/nexup-api";
import { onRegenerateAppointmentNotificationSubmit } from "store/dashboard/event";
import { RegenerateAppointmentNotificationsFormContent } from "./RegenerateAppointmentNotificationsFormContent";

const tk = tkCP.adminPage.dashboard.regenerateAppointmentNotifications;
const formId = "RegenerateAppointmentNotificationsInputForm";

export const RegenerateAppointmentNotificationsInput: FC = () => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (requestData: RegenerateAppointmentNotificationsRequestBody) => {
      setLoading(true);
      const result = await dispatch(
        onRegenerateAppointmentNotificationSubmit(requestData)
      );
      setLoading(false);
      if (result) {
        setIsOpen(false);
      }
    },
    [setLoading, setIsOpen, dispatch]
  );

  const onClick = useCallback(() => setIsOpen(true), [setIsOpen]);
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <>
      <DefaultButton
        title={t(tk.buttonText)}
        text={t(tk.buttonText)}
        onClick={onClick}
      />
      {isOpen && (
        <Popup
          open={isOpen}
          closableHeader
          headerTitle={t(tk.popupTitle)}
          primaryButtonText={t(tk.submitText)}
          disablePrimaryButton={false}
          formId={formId}
          loading={loading}
          onClose={onClose}
          disableBackdropClick
        >
          <Form<RegenerateAppointmentNotificationsRequestBody>
            id={formId}
            onSubmit={onSubmit}
          >
            <RegenerateAppointmentNotificationsFormContent />
          </Form>
        </Popup>
      )}
    </>
  );
};
