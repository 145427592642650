import { StyleVariant, TextVariant, TypeVariant } from "model/themeVariant";
import {
  borderMixin,
  Color,
  createStyle,
  FlexAlignItems,
  FlexJustifyContent,
  flexRowMixin,
  hoverMixin,
  paddingMixin,
  pxToRem
} from "@laba/react-common";
import { RadiusVariant, Theme } from "model/theme";
import { getThemeRadius, themeTextMixin } from "model/themeUtils";
import {
  getGenericButtonBackgroundColor,
  getGenericButtonColor,
  getTypeVariantGenericButtonHoverBackgroundColor
} from "components/utils/getGenericButtonColors";
import { TagPaddingVariant } from "components/tags/Tag/Tag";

export interface TagStyleProps {
  style: StyleVariant;
  type: TypeVariant;
  disabled: boolean;
  isClickable: boolean;
  radius: RadiusVariant;
  backgroundColor?: Color;
  withIcon: boolean;
  paddingVariant: TagPaddingVariant;
}

export const useTagStyle = createStyle(
  (theme: Theme) => ({
    container: (props: TagStyleProps) => ({
      ...borderMixin({
        color: getGenericButtonColor(theme, {
          style: props.style,
          type: props.type,
          backgroundColor: props.backgroundColor,
          disabled: props.disabled
        }),
        style: "solid",
        side: { all: props.type === TypeVariant.Outlined ? 1 : 0 },
        radius: { all: getThemeRadius(theme, props.radius) }
      }),
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.Center,
        alignItems: FlexAlignItems.Center,
        gap: 4
      }),
      ...paddingMixin(
        props.withIcon
          ? {
              topBottom: 4,
              left: props.paddingVariant === TagPaddingVariant.Normal ? 12 : 8,
              right: 8
            }
          : {
              leftRight:
                props.paddingVariant === TagPaddingVariant.Normal ? 12 : 8,
              topBottom: 4
            }
      ),
      ...(props.isClickable && {
        transition: "all 250ms",
        ...hoverMixin({
          ...borderMixin({
            color: getGenericButtonColor(theme, {
              style: props.style,
              type: props.type,
              backgroundColor: props.backgroundColor,
              disabled: props.disabled
            })
          }),
          backgroundColor: getTypeVariantGenericButtonHoverBackgroundColor(
            theme,
            props.style,
            props.type,
            props.backgroundColor
          )
        })
      }),
      backgroundColor: getGenericButtonBackgroundColor(theme, {
        style: props.style,
        type: props.type,
        backgroundColor: props.backgroundColor,
        disabled: props.disabled
      }),
      color: getGenericButtonColor(theme, {
        style: props.style,
        type: props.type,
        disabled: props.disabled,
        backgroundColor: props.backgroundColor
      }),
      height: pxToRem(24),
      width: "max-content",
      display: "inline-flex",
      maxWidth: "100%"
    }),
    text: {
      ...themeTextMixin(theme, TextVariant.Caption, 1)
    },
    startIcon: {
      height: pxToRem(18),
      width: pxToRem(18)
    },
    endIcon: {
      height: pxToRem(18),
      width: pxToRem(18)
    },
    defaultCursor: { cursor: "default" },
    pointerCursor: { cursor: "pointer" }
  }),
  "Tag"
);
