import { WhatsappClient } from "../../communication/whatsappClient/whatsappClient";

export enum KnownWhatsAppClientConnectionStatus {
  Pending = "Pending",
  Failed = "Failed",
  Connected = "Connected",
  Disconnected = "Disconnected",
  Revoked = "Revoked"
}

export enum WhatsAppClientAction {
  ConnectWhatsApp = "ConnectWhatsApp"
}

export interface WhatsAppClientConnect {
  action: WhatsAppClientAction;
  organizationId: string;
}

export interface WhatsAppClientResponse {
  code: WhatsAppClientResponseCode;
  error: WhatsAppClientResponseError;
  qr: string;
  whatsAppClient?: WhatsappClient;
}

export enum WhatsAppClientResponseCode {
  Error = "Error",
  WhatsAppQRUpdate = "WhatsAppQRUpdate",
  WhatsAppConnectionFinish = "WhatsAppConnectionFinish"
}

export enum WhatsAppClientResponseError {
  SocketAlreadyOpen = "SocketAlreadyOpen",
  InvalidClient = "InvalidClient",
  InternalError = "InternalError",
  Timeout = "Timeout"
}
