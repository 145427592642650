import { ApiRequestResponse } from "request/types";
import { getApiUrl, getProvisionalApiUrl, request } from "request/nexupRequest";
import { ApiDate, getKeyObj, HttpMethod } from "@laba/ts-common";
import { ResourceType } from "model/primitives/resourceModel";
import { urlJoin } from "url-join-ts";
import { ModelId } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import { AppointmentStatus } from "model/resource";

export const manteinanceBasePath = "/maintenance";

export interface MaintenanceSyncStatus {
  isEnabled: boolean;
  isOngoing: boolean;
  isEnabledToStart: boolean;
}
export const maintenanceSyncStatusRequest = async (
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.GET,
    url: provisionalServer
      ? getProvisionalApiUrl(`${manteinanceBasePath}/resources/sync`)
      : getApiUrl(`${manteinanceBasePath}/resources/sync`)
  });
};

export enum CompleteConfigurationAction {
  StopSync = "stopSync",
  CompleteIndexes = "completeIndexes",
  UpdateMigrationsApplied = "updateMigrationsApplied",
  InitSubscribers = "initSubscribers"
}
export interface CompleteConfigurationBody {
  actions?: CompleteConfigurationAction[];
}

export const maintenanceCompleteConfigurationRequest = async (
  data: CompleteConfigurationBody,
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: provisionalServer
      ? getProvisionalApiUrl(`${manteinanceBasePath}/configuration/complete`)
      : getApiUrl(`${manteinanceBasePath}/configuration/complete`),
    data
  });
};

export enum SyncResourceCode {
  All = "All",
  Auto = "Auto"
}
export interface SyncResourceClass {
  type?: ResourceType;
  migrationIds?: number[];
}

export interface SyncResourceBody {
  startDate?: ApiDate;
  endDate?: ApiDate;
  count?: number;
  delayWindowMinutes?: number;
  emptySearchLimit?: number;
  classesList?: SyncResourceClass[];
  syncCodes?: SyncResourceCode[];
}

export const maintenanceStartSyncRequest = async (
  data: SyncResourceBody,
  provisionalServer = true
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: provisionalServer
      ? getProvisionalApiUrl(`${manteinanceBasePath}/resources/sync`)
      : getApiUrl(`${manteinanceBasePath}/resources/sync`),
    data
  });
};

export const maintenanceCreatePrescriptionDocumentRequest = async (
  prescriptionId: ModelId
): Promise<ApiRequestResponse<MaintenanceSyncStatus>> => {
  return request({
    method: HttpMethod.POST,
    url: getApiUrl(
      urlJoin(`${manteinanceBasePath}/prescription-document/`, prescriptionId)
    )
  });
};

export interface RegenerateAppointmentNotificationsParams {
  status?: AppointmentStatus[];
  startDate?: ApiDate;
  endDate?: ApiDate;
}

export interface RegenerateAppointmentNotificationsRequestBody {
  id?: ModelId;
  params?: RegenerateAppointmentNotificationsParams;
}

export const RegenerateAppointmentNotificationsParamsKey = getKeyObj(
  createHydratedMock<RegenerateAppointmentNotificationsRequestBody>()
);

export const regenerateAppointmentNotifications = (
  id?: ModelId,
  params?: RegenerateAppointmentNotificationsParams
): Promise<ApiRequestResponse<unknown>> =>
  request({
    method: HttpMethod.POST,
    url: getApiUrl(
      `${manteinanceBasePath}/schedule/${id}/regenerate-notifications`
    ),
    data: params
  });
