import { gt } from "lodash-es";
import { Optional } from "model/types";

export const isOdd = (value: number): boolean => value % 2 === 1;
export const isEven = (value: number): boolean => value % 2 === 0;

export const randomBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const currencyValueToString = (
  currencyValue?: number
): Optional<string> => {
  return currencyValue
    ?.toLocaleString(undefined, {
      useGrouping: false
    })
    .replace(".", ",");
};

export const isMultiple = (num1: number, multipleOf: number): boolean => {
  return num1 / multipleOf >= 1 && num1 % multipleOf === 0;
};

export const isGreaterThan0 = (value?: number): boolean => gt(value, 0);
