import {
  createStyle,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface Props {
  textVariant: TextVariant;
  boldTextVariant: TextVariant;
  maxLines?: number;
  styleVariant: StyleVariant;
  colorVariant: ThemeStyleColorVariant;
}

export const useParagraphWithSpansStyles = createStyle(
  (theme: Theme) => ({
    text: (props: Props) => ({
      ...themeTextMixin(theme, props.textVariant, props.maxLines),
      color: getThemeStyleColor(theme, props.styleVariant, props.colorVariant)
    }),
    bold: (props: Props) => ({
      ...themeTextMixin(theme, props.boldTextVariant),
      color: getThemeStyleColor(theme, props.styleVariant, props.colorVariant)
    })
  }),
  "ParagraphWithSpans"
);
